import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import SyntaxHighlighter from 'react-syntax-highlighter';
import chroma from 'chroma-js';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import 'react-virtualized/styles.css';
import { useTheme } from '@mui/material/styles';
import * as Icons from '@mui/icons-material';
import { Chip, Typography, Tooltip, Switch } from '@mui/material';
import Avatar from './Avatar';

export const GeneralText = ({
  primary,
  size,
  text,
  fontSize,
  style,
  label,
  markdown,
  color,
  beta,
  ui,
  type,
  keyStructure,
  restrictContent,
  structureId,
  maxCharacters,
  uppercase,
  classNameComponent,
  onClick,
}) => {
  const mode = primary
    ? 'storybook-text--primary'
    : 'storybook-text--secondary';

  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructures = businessStructure?.structures;
  const employees = businessPreference?.employees;

  const structure = businessStructures?.find((s) => s.id === structureId);

  const fields = structure?.fields;

  function getStatusFromPreferences(statusLabel) {
    //find the field where the dataType is 'status'
    const statusField = fields?.find((field) => field?.typeData === 'status');

    //find the status in the preferences with the field 'selections' under the status
    const status = statusField?.selections?.find(
      (selection) => selection?.value?.toString() === statusLabel?.toString()
    );

    return status?.label || t('notAvailable');
  }

  function getColorFromPreferences(statusLabel, color, type) {
    //find the field where the dataType is 'status'
    const statusField = fields?.find((field) => field.typeData === 'status');

    //find the status in the preferences with the field 'selections' under the status
    const status = statusField?.selections?.find(
      (selection) => selection?.label?.toString() === statusLabel?.toString()
    );

    const colorValue = color || status?.color || theme.palette.primary.main;
    const validColor = chroma.valid(colorValue) ? colorValue : '#000000';
    const lightColor = chroma(validColor).luminance(0.85).hex();

    return lightColor;
  }

  function getIconFromPreferences(statusLabel, typeData) {
    //find the field where the dataType is 'status'
    const priorityField = fields?.find((field) => field?.typeData === typeData);

    //find the status in the preferences with the field 'selections' under the status
    const icon = priorityField?.selections?.find(
      (selection) => selection?.label?.toString() === statusLabel?.toString()
    )?.icon;

    return icon;
  }

  function getEmployeeAvatar(employee) {
    const employeeData = employees?.find((emp) => emp.id === employee);
    return employeeData?.avatar;
  }

  function getEmployeeName(employee) {
    const employeeData = employees?.find((emp) => emp.id === employee);
    return (
      employeeData?.publicDisplay?.name ||
      employeeData?.displayName ||
      employeeData?.name
    );
  }

  // Check if dark mode is active
  const isDarkMode = theme.palette.mode === 'dark';

  function CustomLinkRenderer({ children, transformedText }) {
    const valueText = transformedText || children;

    const aTags =
      typeof valueText === 'string'
        ? valueText?.match(/<a.*?>.*?<\/a>/g)
        : null;

    const pTags = valueText?.match(/<p.*?>.*?<\/p>/g);

    if ((!aTags || !aTags.length) && !pTags) return children;

    let modifiedText = valueText;

    aTags?.forEach((aTag) => {
      const textMatch = aTag?.match(/>(.*?)<\/a>/);
      const text = textMatch ? textMatch[1] : '';
      modifiedText = modifiedText.replace(aTag, text);
    });

    pTags?.forEach((pTag) => {
      const textMatch = pTag?.match(/>(.*?)<\/p>/);
      const text = textMatch ? textMatch[1] : '';
      modifiedText = modifiedText.replace(pTag, text);
    });

    return (
      <span style={{ fontSize: fontSize || '11px' }}>
        {modifiedText.split(/(\s+)/).map((word, index) => {
          if (word.startsWith('@')) {
            const aTag = aTags.find((tag) => tag.includes(word));

            // Extract id, path, structure, and collection from the tag
            const idMatch = aTag?.match(/id=["']?([^"'\s]*)["']?/);
            const pathMatch = aTag?.match(/path=["']?([^"'\s]*)["']?/);
            const structureMatch = aTag?.match(
              /structure=["']?([^"'\s]*)["']?/
            );
            const collectionMatch = aTag?.match(
              /collection=["']?([^"'\s]*)["']?/
            );

            const id = idMatch ? idMatch[1] : null;
            const path = pathMatch ? pathMatch[1] : null;
            const structure = structureMatch ? structureMatch[1] : null;
            const collection = collectionMatch ? collectionMatch[1] : null;

            if (id && path && structure && collection) {
              const href = `/app/element/${collection}/${structure}/${id}`;

              if (collection === 'employees') {
                return (
                  <a
                    key={index}
                    style={{
                      color: businessPreference?.mainColor,
                      fontSize: fontSize || '11px',
                    }}
                  >
                    {word}
                  </a>
                );
              }

              return (
                <a
                  key={index}
                  href={href}
                  style={{
                    color: businessPreference?.mainColor,
                    fontSize: fontSize || '11px',
                  }}
                >
                  {word}
                </a>
              );
            }
          }
          return word + ' ';
        })}
      </span>
    );
  }

  const transformCustomText = (text) => {
    if (typeof text !== 'string') return '';

    // Replace single asterisks with two newlines
    const modifiedText = text?.replace(/(?<!\*)\*(?!\*)/g, '\n\n\n');
    return modifiedText;
  };

  const transformTextBasedOnType = (text, type) => {
    switch (type) {
      case 'money':
        return text === '-'
          ? '-'
          : `${Number(
              keyStructure === 'price' ||
                keyStructure === 'fees' ||
                keyStructure === 'financesTotal' ||
                keyStructure === 'unitPrice' ||
                keyStructure === 'unity' ||
                keyStructure === 'financesBalance' ||
                keyStructure === 'financesSubtotal' ||
                keyStructure === 'financesTax1' ||
                keyStructure === 'financesTax2' ||
                keyStructure === 'financesTax3'
                ? text / 10000
                : text
            )?.toFixed(2)} $`;
      case 'status':
        const status = color ? text : getStatusFromPreferences(text);
        return status;
      case 'priority':
        const priority = getStatusFromPreferences(text);
        return priority;
      case 'date':
        const dateFormated =
          text?.seconds || text?._seconds || text
            ? moment
                .unix(text?.seconds || text?._seconds || text / 1000)
                .format('DD MMM YYYY')
            : t('noDate');

        return `${dateFormated}`;
      case 'date-time':
        const dateFormatedTime =
          text?.seconds || text?._seconds || text
            ? moment
                .unix(text?.seconds || text?._seconds || text / 1000)
                .format('DD MMM YYYY HH:mm')
            : t('noDate');
        return `${dateFormatedTime}`;
      case 'assigned':
        const userName = text?.name;
        return `${userName}`;

      default:
        return text;
    }
  };

  const stripHtmlTags = (text) => {
    const safeText = typeof text === 'string' ? text : '';

    return safeText.replace(/<\/?[^>]+(>|$)/g, '');
  };

  const iconMap = getIconFromPreferences(text, type);
  const IconDynamic = Icons[iconMap] || Icons.Error;

  const renderContent = () => {
    const validText = typeof text === 'string' ? text : text?.toString();
    let transformedText =
      type === 'date'
        ? text
        : type === 'assigned'
        ? text
        : type === 'tags'
        ? text
        : type === 'date-time'
        ? text
        : transformCustomText(validText);
    transformedText = transformTextBasedOnType(
      transformedText,
      type === 'plainText' ? 'plainText' : type
    );

    if (maxCharacters && transformedText.length > maxCharacters) {
      transformedText = transformedText.substring(0, maxCharacters) + '...';
    }

    let transformedTextSec = stripHtmlTags(transformedText);

    if (markdown) {
      return (
        <div
          className="markdown-content"
          style={{
            color: primary ? (isDarkMode ? '#FFF' : '#000') : '#FFF',
            fontSize: `${fontSize}`,
          }}
        >
          <span>
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              children={transformedTextSec}
            />
          </span>
        </div>
      );
    } else if (type === 'status' || ui === 'chip') {
      return (
        <Chip
          size="small"
          label={transformedText}
          className="smallChipText"
          icon={
            type === 'status' && (
              <IconDynamic fontSize="10px" color={color || '#000'} />
            )
          }
          style={{
            border: `0.5px solid ${color || '#000'}`,
            backgroundColor:
              type === 'status'
                ? getColorFromPreferences(transformedText, color)
                : businessPreference?.mainColor + '10',
            color: color || '#000',
            fontWeight: 500,
            height: '24px',
            marginBottom: '5px',
            fontSize: '10px',
            marginTop: '2px',
          }}
        />
      );
    } else if (type === 'boolean') {
      return (
        <Switch
          checked={Boolean(transformedText)}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          disabled
          className="middle-content"
          sx={{
            marginLeft: '-8px',
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: primary
                ? isDarkMode
                  ? '#FFF'
                  : businessPreference?.mainColor
                : '#FFF',
              width: '38px',
              height: '22px',
              marginTop: '6px',
            },
            '& .MuiSwitch-thumb': {
              width: '15px',
              height: '15px',
              marginTop: '2px',
            },
            height: '35px',
            '& .MuiSwitch-track': {
              borderRadius: '20px',
              height: '16px',
              marginTop: '3px',
            },
            width: '55px',
            maxWidth: '55px',
          }}
        />
      );
    } else if (type === 'tags') {
      let tags = [];
      if (transformedText?.length > 0) {
        tags = transformedText;
      } else {
        tags = [t('noTag'), t('...')];
      }

      const firstTags = restrictContent ? tags?.slice(0, 2) : tags;

      // Ensure firstTags is always an array
      const safeFirstTags = Array.isArray(firstTags) ? firstTags : [];

      return (
        <div>
          {safeFirstTags.map((tag, index) => (
            <Chip
              size="small"
              key={index}
              label={tag}
              className="smallChipTextTags"
              style={{
                backgroundColor: businessPreference?.secColor + '10',
                marginBottom: '5px',
                borderRadius: '7px',
                height: '18px',
                marginRight: '3px',
              }}
            />
          ))}
        </div>
      );
    } else if (
      type === 'assigned' ||
      type === 'assignedToId' ||
      type === 'assignedTo'
    ) {
      return (
        <div className="d-flex middle-content">
          <div>
            <Avatar
              size="xsm"
              img={getEmployeeAvatar(text)}
              alt={getEmployeeName(text)}
              userId={text}
            />
          </div>
          <div className="mx-2 fs-12">{getEmployeeName(text)}</div>
        </div>
      );
    } else if (type === 'slider') {
      return <IconDynamic htmlColor={color} theme={theme} selected={false} />;
    } else if (type === 'selection') {
      return (
        <div className="d-flex middle-content">
          <IconDynamic htmlColor={color} theme={theme} selected={false} />
          <div className="px-2">{transformedText}</div>
        </div>
      );
    } else if (type === 'date' || type === 'date-time') {
      return (
        <div style={{ marginTop: '2px' }} className="d-flex">
          <div>
            <Icons.CalendarMonthOutlined
              htmlColor="#696969"
              fontSize={fontSize || '10px'}
            />
          </div>
          <div className="px-2">
            <span style={{ fontSize: fontSize || '10px' }}>
              {transformedText}
            </span>
          </div>
        </div>
      );
    } else {
      return transformedText;
    }
  };

  return (
    <>
      <div>
        <div
          style={{
            ...style,

            fontSize: `${fontSize}`,
            color: primary ? (isDarkMode ? '#FFF' : '#000') : color || '#FFF',
            textTransform: `${uppercase ? 'uppercase' : 'none'}`,
            lineHeight: 1.15,
            cursor: onClick ? 'pointer' : 'default',
          }}
          className={[
            'storybook-text',
            `storybook-text--${size}`,
            `${classNameComponent}`,
            mode,
          ].join(' ')}
          onClick={onClick}
        >
          {businessPreference?.preferences?.fieldsView === 'reverse' &&
            label &&
            type !== 'status' && (
              <div style={{ marginTop: '-3px', marginBottom: '2px' }}>
                <Typography
                  fontSize="10.5px"
                  sx={{ marginTop: '-20px' }}
                  fontWeight={300}
                  color="#696969"
                  variant="caption"
                >
                  {label}
                </Typography>
              </div>
            )}
          {renderContent()}
          {beta && (
            <Tooltip title={t('betaFunctionLetUsKnow')} placement="bottom">
              <div
                className="d-flex "
                style={{ marginTop: '3px', marginBottom: '2px' }}
              >
                <div>
                  <Icons.TryOutlined color="primary" fontSize="9px" />
                </div>
                <div style={{ marginLeft: '3px', marginTop: '-2px' }}>
                  <Typography
                    fontSize="9px"
                    fontWeight={300}
                    color={businessPreference?.mainColor}
                    variant="caption"
                  >
                    {t('betaFunction')}
                  </Typography>
                </div>
              </div>
            </Tooltip>
          )}
          {businessPreference?.preferences?.fieldsView !== 'reverse' &&
            label &&
            type !== 'status' && (
              <div style={{ marginTop: '-3px', marginBottom: '2px' }}>
                <Typography
                  fontSize="8.5px"
                  sx={{ marginTop: '-20px' }}
                  fontWeight={300}
                  color="#69696980"
                  variant="caption"
                >
                  {label}
                </Typography>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default GeneralText;
