import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Lottie from 'react-lottie';
import loadingAnimation from '../../lotties/nodeLoading.json';
import { Backdrop, Skeleton } from '@mui/material';

function Loading({ type, size }) {
  const resolver = (size) => {
    switch (size) {
      case 'small':
        return 100;
      case 'medium':
        return 200;
      case 'large':
        return 300;
      default:
        return 100;
    }
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {type === 'circle' ? (
        <CircularProgress
          color="primary"
          sx={{
            width:
              size === 'small' ? '20px' : size === 'medium' ? '50px' : '70px',
            height:
              size === 'small' ? '20px' : size === 'medium' ? '50px' : '70px',
          }}
        />
      ) : type === 'skeleton' ? (
        <Skeleton
          variant="rectangular"
          height={
            size === 'small' ? '20px' : size === 'medium' ? '50px' : '70px'
          }
          width={
            size === 'small' ? '50px' : size === 'medium' ? '1000px' : '170px'
          }
        />
      ) : type === 'logo' ? (
        <Lottie
          options={lottieOptions}
          height={resolver(size)}
          width={resolver(size)}
        />
      ) : type === 'backdrop' ? (
        <Backdrop sx={{ zIndex: 9999999 }} />
      ) : (
        <Lottie
          options={lottieOptions}
          height={resolver(size)}
          width={resolver(size)}
        />
      )}
    </Box>
  );
}
export default Loading;
