import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';
import { useParams } from 'react-router-dom';
import * as Icons from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Card from '../../components/@generalComponents/Card';
import { motion } from 'framer-motion';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import { Skeleton } from '@mui/material';
import { setGeneralStatus } from '../../redux/actions-v2/coreAction';
const DragDrop = ({
  isTablet,
  activeModule,
  list,
  customizations,
  isLoading,
  activeIndex,
}) => {
  const { t } = useTranslation();
  const { structureId } = useParams();
  const [cards, setCards] = useState([]);
  const [searchColumnsId, setSearchColumnsId] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const dispatch = useDispatch();

  const childVariants = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  const currentUser = useSelector((state) => state.core.user);

  useEffect(() => {
    let formattedCardsStatus = list?.map((card) => {
      const status =
        card?.values?.find((value) => value?.structureValue === 'status')
          ?.value || 0;

      return {
        ...card,
        status: card?.status || status,
      };
    });

    formattedCardsStatus = formattedCardsStatus?.sort((a, b) => {
      // If both items have status === 0, sort by startDate
      if (a?.status === 0 && b?.status === 0) {
        return (
          (a?.startDate?.seconds ||
            a?.startDate?._seconds ||
            a?.targetDate?.seconds ||
            a?.targetDate?._seconds) -
          (b?.startDate?.seconds ||
            b?.startDate?._seconds ||
            b?.targetDate?.seconds ||
            b?.targetDate?._seconds)
        );
      }

      // If only one item has status === 0, it should come first
      if (a?.status === 0) return -1;
      if (b?.status === 0) return 1;

      // If neither item has status === 0, sort by lastUpdate
      return (
        (b?.lastUpdate?.seconds || b?.lastUpdate?._seconds) -
        (a?.lstUpdate?.seconds || a?.lastUpdate?._seconds)
      );
    });

    if (currentUser?.activeBusiness?.role === 'EMPLOYEE') {
      let filtredList = formattedCardsStatus?.filter(
        (card) => card?.assignedToId === currentUser?.uid
      );
      setCards(filtredList || []);
    } else {
      setCards(formattedCardsStatus || []);
    }
  }, [list]);

  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const darkerColor = (color) => {
    return chroma(color || '#FFF')
      .darken(0.4)
      .hex();
  };

  const activeStructure = businessStructure?.structures?.find(
    (structure) => structure.id === structureId
  );

  const statusColumnsOriginal = activeStructure?.fields?.find(
    (field) => field.value === 'status'
  )?.selections;
  const statusesToHide = customizations?.statusesToHide || {};
  const statusColumns = statusColumnsOriginal?.filter(
    (item) => !statusesToHide[item?.value]
  );

  const lastStatus = statusColumns?.length - 1 || 1;

  function onDragEnd(result) {
    const { destination, source, draggableId } = result;

    const movedCard = cards?.find((card) => card.id === draggableId);
    if (!movedCard) return;

    // Determine the new status based on destination.droppableId
    let newStatus = parseInt(destination?.droppableId || 0);

    // Update the card's status in the state
    const updatedCards = cards?.map((card) => {
      if (card.id === draggableId && newStatus !== 1) {
        return { ...card, status: newStatus };
      }
      if (card.id === draggableId && newStatus === 1) {
        return {
          ...card,
          status: newStatus,
          realStartDate: { seconds: 0, _seconds: 0 },
        };
      }
      return card;
    });

    setCards(updatedCards);

    // Update the card's status in the database
    updatePass(draggableId, newStatus, movedCard);
  }

  const updatePass = async (draggableId, newStatus, movedCard) => {
    let formatedPath = movedCard?.documentPath.split('/');
    formatedPath = formatedPath
      .filter((part) => part !== draggableId)
      .join('/');
    try {
      if (newStatus === lastStatus) {
        setShowConfetti(draggableId);
      }
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'drag-drop-kanban',
          type: 'pulse',
        })
      );
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `updateFieldV2`,
        body: {
          documentId: draggableId,
          elementPath: formatedPath,
          key: 'status',
          value: newStatus,
        },
      });
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'drag-drop-kanban',
          type: 'pulse',
        })
      );
      setTimeout(() => {
        setShowConfetti(false);
      }, 4000);
    } catch (error) {
      console.error(error);
    }
  };

  const screenWidth = window.innerWidth - 430;
  const cardWidth =
    statusColumns?.length > 0 ? screenWidth / statusColumns?.length : 100;

  const handleSearch = (e, columnId) => {
    const searchTerm = e.target.value;
    if (searchTerm === '') {
      setCards(list);
      setSearchColumnsId(null);
      return;
    } else {
      // Filter the items in the column based on the search term
      const filteredItems = cards
        ?.filter((item) => item?.status === columnId)
        ?.filter((item) =>
          item?.targetName?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      setCards(filteredItems);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="d-flex p-3">
        {statusColumns?.map((column) => {
          const IconComponent = Icons[column.icon] || Icons.Error;
          return (
            <Droppable
              key={column?.value}
              droppableId={column?.value?.toString()}
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    display: 'flex',
                    height: '88vh',
                    margin: '4px',
                    width: 98 / statusColumns?.length + '%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: '90px',
                    maxWidth: '450px',
                    alignContent: 'flex-start',
                    borderRadius: '6px',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: 'white',
                      padding: '10px',
                      width: '100%',
                      borderRadius: '10px',
                      color: darkerColor(column?.color),
                      fontSize: '15px',
                      fontWeight: 600,
                    }}
                    className="justify-content-between d-flex px-3 middle-content"
                  >
                    {column?.label}

                    <div>
                      {searchColumnsId === column?.value ? (
                        <>
                          {customizations?.displaySearch && (
                            <input
                              type="search"
                              style={{
                                border: 'none',
                                outline: 'none',
                                width: '100px',
                                backgroundColor: 'transparent',
                              }}
                              placeholder={t('search') + '...'}
                              onChange={(e) => handleSearch(e, column?.value)}
                            />
                          )}
                        </>
                      ) : (
                        <div className="d-flex">
                          <>
                            {customizations?.displaySearch && (
                              <Icons.SearchOutlined
                                htmlColor="#f2f2f2"
                                sx={{ cursor: 'pointer' }}
                                onClick={() =>
                                  setSearchColumnsId(column?.value)
                                }
                              />
                            )}
                          </>

                          <>
                            {customizations?.displayTotal && (
                              <div
                                style={{ marginTop: '2px', marginRight: '4px' }}
                              >
                                {
                                  cards?.filter(
                                    (card) => card?.status === column?.value
                                  )?.length
                                }
                              </div>
                            )}
                          </>
                          <IconComponent />
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ overflow: 'scroll', padding: '10px' }}>
                    {isLoading && (
                      <div>
                        {Array.from({
                          length: Math.floor(Math.random() * 4) + 1,
                        }).map((_, index) => (
                          <motion.div
                            variants={childVariants}
                            key={index}
                            className="mx-2 mt-2"
                          >
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              width={cardWidth + 'px'}
                              height={100}
                            />
                          </motion.div>
                        ))}
                      </div>
                    )}
                    {cards?.length > 0 &&
                      cards
                        ?.filter((card) => card?.status === column?.value)
                        ?.map((card, index) => (
                          <Draggable
                            key={card.id}
                            draggableId={String(card.id)}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                style={{
                                  width: cardWidth - 10 + 'px',
                                  maxWidth: '480px',
                                  textAlign: 'center',
                                  overflow: 'hidden',
                                }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Card
                                  cardWidth={cardWidth}
                                  item={card}
                                  activeModule={activeModule}
                                  index={index}
                                  color={column?.color}
                                  key={`${card.id}`}
                                  isTablet={isTablet}
                                  showConfetti={showConfetti}
                                  activeIndex={activeIndex}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          );
        })}
      </div>
    </DragDropContext>
  );
};

export default DragDrop;
