import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import chroma from 'chroma-js';

// utilities
import { useTranslation } from 'react-i18next';
import * as drawerActions from '../../../redux/actions-v2/drawer-actions';
import _ from 'lodash';

import ListItem from '@mui/material/ListItem';
import Avatar from '../../../stories/general-components/Avatar';

import { ListItemAvatar, ListItemText, Box } from '@mui/material';
import GeneralText from '../../../stories/general-components/GeneralText';
import Chip from '../../../stories/general-components/Chip';

const ListAItem = ({ activeModule, element }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { structureId, moduleName } = useParams();

  const handleClick = async (item, index) => {
    {
      activeModule?.list?.preferences?.onClick !== 'quick'
        ? navigate(
            `/app/element/${moduleName}/${structureId}/${
              item?.documentIdentifiant || item?.id
            }?tab=0`
          )
        : handleQuickview(item);
    }
  };

  const handleClose = () => {
    dispatch(drawerActions.viewElement({ isDrawerOpen: false }));
  };

  const handleQuickview = (item) => {
    dispatch(
      drawerActions.viewElement({
        isDrawerOpen: true,
        item: item,
        handleDrawerClose: handleClose,
        type:
          activeModule?.list?.preferences?.onClick === 'edit' ? 'edit' : 'view',
      })
    );
  };

  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructures = useSelector(
    (state) => state.core.structure
  )?.structures;

  const mainColor = businessPreference?.mainColor || '#000000';

  const getStatusColor = (count) => {
    const intensity = count / 10;
    return chroma.mix('grey', mainColor, intensity).hex();
  };

  return (
    <ListItem
      key={element?.id}
      dense
      disableRipple
      disableTouchRipple
      divider
      button
      sx={{ cursor: 'pointer', height: '55px' }}
    >
      {' '}
      {element?.values?.map((col, idx) => {
        return (
          <Box
            sx={{ width: `${col?.width}%`, paddingLeft: '10px' }}
            key={idx + 'item'}
          >
            {(col?.typeValue === 'text' ||
              col?.typeValue === 'string' ||
              col?.typeValue === 'money' ||
              col?.typeValue === 'number' ||
              col?.typeValue === 'date' ||
              col?.typeValue === 'date-time' ||
              col?.typeValue === 'tags' ||
              col?.typeValue === 'status' ||
              col?.typeValue === 'selection' ||
              col?.typeValue === 'boolean' ||
              col?.typeValue === 'assignedTo' ||
              col?.typeValue === 'assignedToId') && (
              <>
                {col?.fieldType === 'chip' ? (
                  <div style={{ paddingRight: '40px' }}>
                    <Chip
                      color={col?.valueColor || 'primary'}
                      label={col?.transformedValue ?? col?.value ?? ''}
                      size="small"
                      fontWeight={600}
                      sx={{
                        fontWeight: 600,
                        width: '100%',
                      }}
                      onClick={() => handleClick(element)}
                    />
                  </div>
                ) : (
                  <ListItemText
                    primary={
                      <GeneralText
                        primary={col?.valueColor === 'primary' ? true : false}
                        size="medium"
                        fontSize="11px"
                        keyStructure={col?.structureValue}
                        maxCharacters={54}
                        restrictContent
                        text={col?.transformedValue ?? col?.value ?? ''}
                        type={col?.typeValue || 'string'}
                        color={col?.valueColor ?? 'primary'}
                      />
                    }
                    secondary={
                      <>
                        {col?.sub?.value !== undefined &&
                          col?.sub?.value !== null && (
                            <GeneralText
                              primary={
                                col?.sub?.valueColor === 'primary'
                                  ? true
                                  : false
                              }
                              size="regular"
                              fontSize="10px"
                              keyStructure={col?.sub?.structureValue}
                              structureId={col}
                              key={col?.structureValue}
                              color={col?.sub?.valueColor ?? 'primary'}
                              text={
                                typeof (
                                  col?.sub?.transformedValue ??
                                  col?.sub?.value ??
                                  ''
                                ) === 'string'
                                  ? String(
                                      col?.sub?.transformedValue ||
                                        col?.sub?.value ||
                                        '-'
                                    ).substring(0, 150)
                                  : col?.sub?.transformedValue ||
                                    col?.sub?.value ||
                                    '-'
                              }
                              type={col?.sub?.typeValue ?? 'text'}
                            />
                          )}
                      </>
                    }
                    onClick={() => handleClick(element)}
                  />
                )}
              </>
            )}
            {col?.typeValue === 'custom:statuses' && (
              <div className="d-flex-3d">
                {[0, 1, 2, 3].map((status) => {
                  const statusCount = col?.value?.[`status${status}`] ?? 0;
                  return (
                    <div
                      key={status}
                      style={{
                        backgroundColor: getStatusColor(statusCount),
                        width: '25px',
                      }}
                      className="status-square"
                    >
                      {statusCount}
                    </div>
                  );
                })}
              </div>
            )}

            {(col?.typeValue === 'avatar' ||
              col?.typeValue === 'media' ||
              col?.typeValue === 'media-single') && (
              <ListItemAvatar>
                <Avatar
                  onClick={() => handleClick(element)}
                  img={
                    col?.value ||
                    `https://storage.googleapis.com/node-business-logos/${element?.businessId}.png` ||
                    ''
                  }
                  name={element?.name ?? ''}
                  alt={col?.value ?? ''}
                  sx={{
                    maxWidth: `${'36px !important'}`,
                    maxHeight: `${'36px !important'}`,
                    borderRadius: '6px !important',
                    padding: '3px',
                  }}
                />
              </ListItemAvatar>
            )}
          </Box>
        );
      })}
    </ListItem>
  );
};

export default ListAItem;
