import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Divider, Grid, Typography } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import GeneralText from '../../../stories/general-components/GeneralText';

const CardItems = ({ items, card, includeGroupsTotal }) => {
  const { t } = useTranslation();
  const [groupedItems, setGroupedItems] = useState([]);
  const safeItems = Object?.values(items);
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (safeItems) {
      // Group and sort items
      const grouped =
        safeItems &&
        safeItems?.reduce((acc, item) => {
          const groupKey = item?.group || 'other'; // Default group if not specified
          if (!acc[groupKey]) {
            acc[groupKey] = { services: [], others: [] };
          }
          if (item.isService) {
            acc[groupKey].services.push(item);
          } else {
            acc[groupKey].others.push(item);
          }
          return acc;
        }, {});

      // Sort each group internally
      Object.values(grouped).forEach((group) => {
        group.services.sort((a, b) => a.name.localeCompare(b.name));
        group.others.sort((a, b) => a.name.localeCompare(b.name));
      });

      // Flatten groups into a sorted array
      const sortedItems = Object.entries(grouped).map(([groupKey, group]) => ({
        groupKey,
        items: [...group.services, ...group.others],
      }));

      setGroupedItems(sortedItems);
    }
  }, [items]);

  return (
    <>
      {isXsScreen ? (
        <Grid container spacing={2}>
          {groupedItems.map(({ groupKey, items }) =>
            items.map((item, index) => (
              <Grid item xs={12} key={item.itemId || index}>
                <GeneralText
                  fontSize="11px"
                  size="bold"
                  text={item?.name?.split('(')[0]}
                  primary={true}
                />
                <Typography variant="body2" fontSize="11px">
                  {item?.sku}
                </Typography>
                <div className="d-flex">
                  <Typography className="col-3" variant="body2" fontSize="11px">
                    {' '}
                    {(item?.finances?.unity / 10000)?.toFixed(2) + ' $'}
                  </Typography>
                  <Typography className="col-3" variant="body2" fontSize="11px">
                    {item?.quantity?.toFixed(2)}
                  </Typography>
                  <Typography
                    fontWeight={500}
                    fontSize="11px"
                    className="col-6 align-right"
                    variant="body2"
                  >
                    {(item?.subtotal / 10000)?.toFixed(2) + ' $'}
                  </Typography>
                </div>

                <Typography
                  fontSize="11px"
                  sx={{ marginTop: '5px' }}
                  variant="body2"
                  color="textSecondary"
                >
                  {item?.finances?.options?.map((option, index) => (
                    <div className="fs-11 d-flex">
                      <p className="fs-11 fw-600"> {t('optionAdded')}: </p>
                      <p className="fs-11 mx-2">
                        {' ' +
                          option?.name +
                          ' (+' +
                          (option?.price / 10000)?.toFixed(2) +
                          ' $)'}
                      </p>
                    </div>
                  ))}
                </Typography>
                {item?.fees !== 0 && (
                  <Typography
                    fontSize="11px"
                    sx={{ marginTop: '5px' }}
                    variant="body2"
                    color="textSecondary"
                  >
                    <div className="fs-11 d-flex">
                      <p className="fs-11 fw-600"> {t('specialFees')}: </p>
                      <p className="fs-11 mx-2">
                        {' ' +
                          ' (+' +
                          (item?.finances?.fees / 10000)?.toFixed(2) +
                          ' $)'}
                      </p>
                    </div>
                  </Typography>
                )}
                <Typography
                  sx={{ marginTop: '5px' }}
                  fontSize="11px"
                  variant="body2"
                  color="textSecondary"
                >
                  {item?.description}
                </Typography>
                <Divider component="div" />
              </Grid>
            ))
          )}
        </Grid>
      ) : (
        <TableContainer>
          <Table aria-label="invoice items" size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: '11px',
                    py: 1.5,
                  }}
                  colSpan={3}
                >
                  {t('name')}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '11px',
                  }}
                  colSpan={2}
                >
                  {t('reference')}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '11px',
                  }}
                  colSpan={1}
                >
                  {t('unity')}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '11px',
                  }}
                  colSpan={1}
                >
                  {t('quantity')}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '11px',
                  }}
                  colSpan={1}
                >
                  {t('subtotal')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupedItems?.map(({ groupKey, items }) => (
                <>
                  {items?.map((item, index) => (
                    <>
                      <TableRow key={item?.id || index}>
                        <TableCell
                          colSpan={3}
                          sx={{
                            border:
                              item?.description &&
                              item?.description !== '-' &&
                              0,
                          }}
                          component="th"
                          scope="row"
                        >
                          <GeneralText
                            fontSize="11px"
                            size="bold"
                            text={item?.name?.split('(')[0]}
                            primary={true}
                          />
                        </TableCell>

                        <TableCell
                          colSpan={2}
                          sx={{
                            border:
                              item?.description &&
                              item?.description !== '-' &&
                              0,
                          }}
                        >
                          <GeneralText
                            fontSize="11px"
                            size="medium"
                            text={item?.sku}
                            primary={true}
                          />
                        </TableCell>

                        <TableCell
                          colSpan={1}
                          sx={{
                            border:
                              item?.description &&
                              item?.description !== '-' &&
                              0,
                          }}
                        >
                          <GeneralText
                            fontSize="11px"
                            size="regular"
                            text={
                              (item?.finances?.unity / 10000)?.toFixed(2) + ' $'
                            }
                            primary={true}
                          />
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          sx={{
                            border:
                              item?.description &&
                              item?.description !== '-' &&
                              0,
                          }}
                        >
                          <GeneralText
                            fontSize="11px"
                            size="regular"
                            text={item?.quantity}
                            primary={true}
                          />
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          sx={{
                            border:
                              item?.description &&
                              item?.description !== '-' &&
                              0,
                          }}
                        >
                          <GeneralText
                            fontSize="11px"
                            size="regular"
                            text={`${(
                              item?.finances?.subtotal / 10000
                            )?.toFixed(2)}$`}
                            primary={true}
                          />
                        </TableCell>
                      </TableRow>

                      {item?.finances?.options?.length > 0 && (
                        <TableRow>
                          <TableCell sx={{ border: 0 }} colSpan={8}>
                            <Typography
                              fontSize="10px"
                              fontWeight={400}
                              color="black"
                              sx={{ marginTop: '-10px' }}
                            >
                              {item?.finances?.options?.map((option, index) => (
                                <div className="fs-11 d-flex">
                                  <p className="fs-11 fw-600">
                                    {' '}
                                    {t('optionAdded')}:
                                  </p>
                                  <p className="fs-11 mx-2">
                                    {' '}
                                    {' ' +
                                      option?.name +
                                      ' (+' +
                                      (option?.price / 10000)?.toFixed(2) +
                                      ' $)'}
                                  </p>
                                </div>
                              ))}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                      {item?.finances?.fees !== 0 && (
                        <TableRow>
                          <TableCell sx={{ border: 0 }} colSpan={8}>
                            <Typography
                              fontSize="10px"
                              fontWeight={400}
                              color="black"
                              sx={{ marginTop: '-10px' }}
                            >
                              <div className="fs-11 d-flex">
                                <p className="fs-11 fw-600">
                                  {' '}
                                  {t('specialFees')}:
                                </p>
                                <p className="fs-11 mx-2">
                                  {' '}
                                  {' ' +
                                    ' (+' +
                                    (item?.fees / 10000)?.toFixed(2) +
                                    ' $)'}{' '}
                                  {!card?.isInvoiced && '*'}
                                </p>
                              </div>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                      {item?.description && item?.description !== '-' && (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <GeneralText
                              fontSize="10px"
                              size="regular"
                              text={item?.description}
                              primary={true}
                              style={{ marginTop: '-10px' }}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
                  {includeGroupsTotal && (
                    <TableRow>
                      <TableCell colSpan={3}>
                        {' '}
                        <Typography
                          fontSize="11px"
                          fontWeight={400}
                          color="#69696980"
                        >
                          {t('subtotalOfGroup')}{' '}
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={3}>
                        {' '}
                        <Typography
                          fontSize="11px"
                          fontWeight={400}
                          color="#69696980"
                        >
                          {items[0]?.targetProfileName}
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={1}>
                        <GeneralText
                          fontSize="11px"
                          size="regular"
                          text={items?.reduce(
                            (acc, item) => acc + item?.quantity,
                            0
                          )}
                          primary={true}
                        />
                      </TableCell>
                      <TableCell colSpan={1}>
                        <GeneralText
                          fontSize="11px"
                          size="regular"
                          text={`${items
                            ?.reduce(
                              (acc, item) =>
                                acc + item?.finances?.subtotal / 10000,
                              0
                            )
                            ?.toFixed(2)}$`}
                          primary={true}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell
                      sx={{ height: '20px', border: 0 }}
                      colSpan={5}
                    ></TableCell>
                  </TableRow>
                </>
              ))}

              <TableRow style={{ marginTop: '10px' }}>
                <TableCell colSpan={6} sx={{ border: 0 }}></TableCell>
                <TableCell
                  sx={{
                    fontSize: '11px',
                  }}
                >
                  <GeneralText
                    fontSize="11px"
                    size="regular"
                    text={t('subtotal')}
                    primary={true}
                  />
                </TableCell>
                <TableCell>
                  <GeneralText
                    fontSize="11px"
                    size="regular"
                    text={`${(card?.finances?.subtotal / 10000)?.toFixed(2)}$`}
                    primary={true}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={6} sx={{ border: 0 }}></TableCell>
                <TableCell
                  sx={{
                    fontSize: '11px',
                  }}
                >
                  <GeneralText
                    fontSize="11px"
                    size="regular"
                    text={card?.businessData?.taxIdName || t('tax1')}
                    primary={true}
                  />
                </TableCell>
                <TableCell>
                  {' '}
                  <GeneralText
                    fontSize="11px"
                    size="regular"
                    text={`${(card?.finances?.tax1 / 10000)?.toFixed(2)}$`}
                    primary={true}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={6} sx={{ border: 0 }}></TableCell>
                <TableCell
                  sx={{
                    fontSize: '11px',
                  }}
                >
                  <GeneralText
                    fontSize="11px"
                    size="regular"
                    text={card?.businessData?.taxIdNameSecond || t('tax2')}
                    primary={true}
                  />
                </TableCell>
                <TableCell>
                  {' '}
                  <GeneralText
                    fontSize="11px"
                    size="regular"
                    text={`${(card?.finances?.tax2 / 10000)?.toFixed(2)}$`}
                    primary={true}
                  />
                </TableCell>
              </TableRow>
              {card?.finances?.fees !== 0 && (
                <TableRow>
                  <TableCell colSpan={6} sx={{ border: 0 }}></TableCell>
                  <TableCell>
                    <GeneralText
                      fontSize="11px"
                      size="regular"
                      text={t('fees')}
                      primary={true}
                    />
                  </TableCell>
                  <TableCell>
                    <GeneralText
                      fontSize="11px"
                      size="regular"
                      text={`${(card?.finances?.fees / 10000)?.toFixed(2)}$`}
                      primary={true}
                    />
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell colSpan={6} sx={{ border: 0 }}></TableCell>
                <TableCell>
                  <GeneralText
                    fontSize="11px"
                    size="medium"
                    text={t('total')}
                    primary={true}
                  />
                </TableCell>
                <TableCell>
                  {' '}
                  <GeneralText
                    fontSize="11px"
                    size="medium"
                    text={`${(card?.finances?.total / 10000)?.toFixed(2)}$`}
                    primary={true}
                  />
                </TableCell>
              </TableRow>
              {card?.isInvoiced && (
                <TableRow>
                  <TableCell colSpan={6} sx={{ border: 0 }}></TableCell>
                  <TableCell sx={{ border: 0 }}>
                    <GeneralText
                      fontSize="11px"
                      size="regular"
                      text={t('balance')}
                      primary={true}
                    />
                  </TableCell>
                  <TableCell sx={{ border: 0 }}>
                    {' '}
                    <GeneralText
                      fontSize="11px"
                      size="regular"
                      text={`${(card?.finances?.balance / 10000)?.toFixed(2)}$`}
                      primary={true}
                    />
                  </TableCell>
                </TableRow>
              )}
              {groupedItems?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    {t('empty')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!card?.isInvoiced && (
            <p className="fs-10 mt-3">
              *Si applicable, les frais spéciaux et taxes supplémentaires seront
              calculées sur la facture finale.
            </p>
          )}
        </TableContainer>
      )}
    </>
  );
};

export default CardItems;
