import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

//components
import MainLayoutV2 from '../../layouts/MainLayoutV2';
import Block from '../../stories/layout-components/Block';

const Workflow = () => {
  const { t, i18n } = useTranslation();
  const currentLangCode = i18n.language;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <MainLayoutV2 pageTitle={t('workflows')} elementId="node">
      <Block height={1} heightPercentage={82}></Block>
    </MainLayoutV2>
  );
};

export default Workflow;
