// src/contexts/ThemeContext.js

import { createContext, useContext, useState } from 'react';

export const ThemeContext = createContext({});

export const useThemeCont = () => {
  return useContext(ThemeContext);
};

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const CustomThemeProvider = ({ children }) => {
  const mode = localStorage.getItem('mode') || 'light';

  const toggleTheme = () => {
    const currentMode = localStorage.getItem('mode') || 'light';
    const newMode = currentMode === 'light' ? 'dark' : 'light';
    localStorage.setItem('mode', newMode);
    // Trigger a re-render
    window.location.reload();
  };

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
