import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import WebsiteLayout from '../../layouts/WebsiteLayout';

const Pricing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tiers = [
    {
      title: 'Node Starter',
      subheader: t('startWithNode'),
      freq: '/mo',
      price: '49,95$',
      description: [
        t('yourBusinessNodePage'),
        t('creditCardRates'),
        t('createInvoicesQuotes'),
        t('accessGeneralModules'),
      ],
      buttonText: t('getStartedNow'),
      buttonVariant: 'contained',
    },
    {
      title: 'All-in-One',
      subheader: t('allInclusiveNode'),
      freq: '/mo',
      price: '199,95$',
      description: [
        t('everythingStarter'),
        t('upTo3Modules'),
        t('accessNodeAI'),
        t('creationLimit'),
        t('onlineRegistrationBooking'),
      ],
      buttonText: t('getStartedNow'),
      buttonVariant: 'contained',
    },
    {
      title: 'Entreprise',
      freq: ' ',
      price: t('contactUs'),
      subheader: t('limitlessFlexibility'),
      description: [
        t('allNoLimit'),
        t('pricingUsage'),
        t('noLimitUsage'),
        t('noLimitUsers'),
        t('automationsEmails'),
      ],
      buttonText: t('getStartedNow'),
      buttonVariant: 'contained',
    },
  ];

  return (
    <WebsiteLayout>
      <Container
        maxWidth="md"
        component="main"
        sx={{ marginBottom: '80px', marginTop: '100px' }}
      >
        <div className="px-5">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
            fontSize="32px"
            fontWeight={500}
          >
            {t('pricing')}
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            component="p"
          >
            {t('pricingDesc')}
          </Typography>
        </div>
        <Grid container spacing={5} alignItems="flex-end" sx={{ mt: 3 }}>
          {tiers.map((tier) => (
            <Grid item key={tier.title} xs={12} sm={12} md={4}>
              <Card
                elevation={0}
                sx={{ borderRadius: '20px', border: '0.5px solid #000' }}
              >
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  sx={{
                    backgroundColor: (theme) => theme.palette.grey[200],
                  }}
                />
                <CardContent>
                  <div className="mt-2">
                    <Typography variant="h4" color="textPrimary">
                      {tier.price}
                      <Typography
                        component="span"
                        variant="h6"
                        color="textSecondary"
                      >
                        {tier.freq || ''}
                      </Typography>
                    </Typography>
                  </div>
                  <div className="mt-4 mb-5">
                    {tier.description.map((line) => (
                      <div className="mt-1">
                        <Typography
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </WebsiteLayout>
  );
};

export default Pricing;
