import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import DrawerSide from '../stories/layout-components/DrawerSide';
import GeneralText from '../stories/general-components/GeneralText';
import Button from '../stories/general-components/Button';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import nodeAxiosFirebase from '../utils/nodeAxiosFirebase';
import { DialogContent } from '@material-ui/core';
import FieldComponent from '../components/@generalComponents/FieldComponent';
import { toast } from 'react-toastify';

const LeadDetails = ({ tile, isDrawerOpen, handleDrawerClose }) => {
  const { t } = useTranslation();
  const [toEdit, setToEdit] = useState(false);
  const [businessdetails, setBusinessDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [businessCatchId, setBusinessCatchId] = useState(null);
  const [structureCatchId, setStructureCatchId] = useState(null);
  const [loadingBusiness, setLoadingBusiness] = useState(false);
  const [exp, setExp] = useState(null);
  const [data, setData] = useState({});
  const [overview, setOverview] = useState(null);
  const [sturcture, setSturcture] = useState(null);
  const [auth, setAuth] = useState(null);
  const [image, setImage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const businessPreference = useSelector((state) => state.core.businessData);

  const getDetails = async (tile) => {
    try {
      setBusinessDetails(null);
      setOverview(null);
      setLoading(true);
      const response = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `tiles`,
        body: {
          latitude: tile?.geometry?.location?.lat,
          longitude: tile?.geometry?.location?.lng,
          zoomLevel: 17,
        },
      });
      const responseDetails = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `businessDetails`,
        body: {
          placeId: tile?.place_id,
          fields: [
            'name',
            'formatted_address',
            'formatted_phone_number',
            'website',
            'reviews',
            'rating',
            'type',
            'url',
            'business_status',
            'opening_hours',
            'price_level',
          ],
        },
      });
      setBusinessDetails(responseDetails?.data);
      setImage(response.url);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getStructureAuth = async (structureId, businessId) => {
    try {
      const transferStruc = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `transferDataAccount`,
        body: {
          businessId: businessId,
          structureId: structureId,
        },
      });

      setSturcture(transferStruc?.structure);
      setAuth(transferStruc?.code);
      setBusinessCatchId(businessId);
      setStructureCatchId(structureId);
      setExp(transferStruc?.exp);
      setData({
        ...data,
        name: businessdetails?.name,
        targetName: businessdetails?.name,
        targetAddress: businessdetails?.formatted_address,
        targetPhone: businessdetails?.formatted_phone_number,
        website: businessdetails?.website,
        tags: businessdetails?.types || [],
        tile: image || '',
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBusiness(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (businessdetails?.website) {
      fetchData();
    }
  }, [businessdetails?.website, image]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setData({});
    setBusinessCatchId(null);
    setStructureCatchId(null);
    setSturcture(null);
    setAuth(null);
  };

  const fetchData = async () => {
    try {
      const suggestion = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `https://geminiup-kjeoh6r3gq-uc.a.run.app`,
        noAuth: true,
        body: {
          prompt: `Can you give me some keywords and a summary of the content of this website:${businessdetails?.website}.  In addition, can you tell me what technologies are used to build this website and whether external integrations (reservations, analysis, etc.) are added?`,
          contact: '',
          business: businessdetails?.name,
          details:
            businessdetails?.types?.map((type) => type)?.join(', ') || '',
          generationConfig: {
            temperature: 0.2,
            topp: 0.2,
            topk: 5,
            tokens: parseInt(1024),
          },
        },
      });
      setOverview(suggestion);
      setLoading(false);
      // Process paymentsData as needed for your application
    } catch (error) {
      console.error('Error fetching payments:', error);
    }
  };

  const handleFieldChange = (fieldId, value) => {
    let convertedValue;
    convertedValue = value;
    setData((prevState) => ({ ...prevState, [fieldId]: convertedValue }));
  };

  const handleCreate = async () => {
    const formatDatesInObject = (obj) => {
      for (let key in obj) {
        if (moment.isMoment(obj[key])) {
          obj[key] = obj[key].utc().format();
        }
      }
      return obj;
    };

    const formatedData = {
      name: data?.name || 'test',
      tags: data?.tags || [],
      elementPath: '/grids',
      businessCreationId: businessCatchId,
      code: auth,
      structureId: structureCatchId,
      data: formatDatesInObject({
        ...data,
      }),
    };

    let newErrors = {};
    sturcture?.fields?.forEach((field) => {
      if (
        field.required &&
        (data[field.value] === undefined || data[field.value] === null)
      ) {
        newErrors[field.value] = 'This field is required';
      }
    });

    // If there are any errors, don't submit the form
    if (Object.keys(newErrors)?.length > 0) {
      return;
    }

    try {
      setLoading(true);
      const response = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `mainCreationV4`,
        body: formatedData,
      });
      const newElementId = response.elementId;

      if (newElementId) {
        handleCloseDialog();
        toast.success(t('created'));
      } else {
        setLoading(false);
        console.error('Element ID not returned from creation API');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDetails(tile);
  }, [tile]);

  const handleTransfer = () => {
    handleCreate();
  };

  const handleInitiateTransfer = () => {
    setSturcture(null);
    setAuth(null);
    setOpenDialog(true);
  };

  const handleSelectionBusiness = (business) => {
    setLoadingBusiness(true);
    getStructureAuth(business?.structureId, business?.businessId);
  };

  return (
    <DrawerSide
      title={businessdetails?.name || '...'}
      subtitle={'0' + ' ' + t('peopleFound')}
      handleDrawerClose={handleDrawerClose}
      isDrawerOpen={isDrawerOpen}
      elementName={businessdetails?.name}
      setToEdit={setToEdit}
      toEdit={toEdit}
      lastUpdate={businessdetails?.lastUpdate}
      elementPath={businessdetails?.documentPath}
      elementId={businessdetails?.id}
      businessdetails={businessdetails}
    >
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{t('transferData')}</DialogTitle>
        <DialogContent>
          <>
            {' '}
            {sturcture && auth && (
              <div>
                <Typography
                  variant="body2"
                  fontSize="11px"
                  fontWeight={400}
                  color="grey"
                  gutterBottom
                  style={{
                    backgroundColor: '#f7f7f7',
                    padding: '10px',
                    borderRadius: '5px',
                  }}
                  className="code-text"
                >
                  {t('code') +
                    ': ' +
                    auth +
                    ' - ' +
                    t('exp') +
                    ' ' +
                    moment
                      .unix(exp?.seconds || exp._seconds)
                      .format('DD MMM HH:mm')}
                </Typography>
              </div>
            )}
          </>

          {!sturcture && !auth && !loadingBusiness && (
            <List>
              {businessPreference?.connected?.map((item, index) => {
                return (
                  <ListItem
                    button
                    divider
                    dense
                    key={index}
                    onClick={() => handleSelectionBusiness(item)}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: 600,
                      }}
                      primary={item?.name}
                      secondary={item?.structureName}
                    />
                  </ListItem>
                );
              })}
            </List>
          )}
          {loadingBusiness && <CircularProgress color="secondary" />}
          {sturcture && auth && (
            <>
              {sturcture &&
                sturcture?.fields?.map((field) => (
                  <FieldComponent
                    key={field?.value}
                    field={field}
                    value={data[field?.value]}
                    onChange={handleFieldChange}
                  />
                ))}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {sturcture && auth && (
            <Button
              onClick={handleTransfer}
              color="primary"
              fullWidth
              buttonSx={{ mt: 2, mb: 2 }}
              label={t('create')}
            />
          )}
        </DialogActions>
      </Dialog>
      <div className="row">
        {loading && (
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
            }}
          />
        )}
        {!loading && (
          <>
            <div className="col-6">
              <img src={image} alt="Tile" />
            </div>
            <div className="col-6">
              <GeneralText
                text={businessdetails?.name}
                fontSize="16px"
                size="bold"
                label={t('name')}
                primary={true}
              />
              <GeneralText
                text={businessdetails?.formatted_address}
                fontSize="13px"
                size="medium"
                label={t('address')}
                primary={true}
              />
              <GeneralText
                text={businessdetails?.types?.map((type) => type)?.join(', ')}
                fontSize="12px"
                size="regular"
                label={t('type')}
                primary={true}
              />
              <GeneralText
                text={businessdetails?.rating + ' / 5'}
                fontSize="12px"
                size="regular"
                label={t('rating')}
                primary={true}
              />
              <GeneralText
                text={businessdetails?.formatted_phone_number}
                fontSize="12px"
                style={{
                  marginTop: '10px',
                }}
                size="regular"
                label={t('phone')}
                primary={true}
              />
              <div className="row">
                <Button
                  variant="text"
                  color="secondary"
                  endIcon="OpenInNewOutlined"
                  size="small"
                  sx={{
                    mt: 2,
                    ml: 1,
                  }}
                  disableElevation
                  label={t('website')}
                  onClick={() =>
                    window.open(businessdetails?.website, '_blank')
                  }
                />
              </div>
            </div>
            <div className="col-12 mt-5">
              <Typography variant="h6" gutterBottom>
                {t('resume')}
              </Typography>
              <GeneralText
                text={overview?.generatedContent}
                fontSize="12px"
                style={{
                  marginTop: '10px',
                }}
                size="regular"
                markdown
                primary={true}
              />
            </div>
            <div className="col-12 mt-5">
              <List>
                <Typography variant="h6" gutterBottom>
                  {t('people')}
                </Typography>
                {/* {persons?.people?.map((person, index) => {
              return (
                <ListBusinessDetails divider key={index}>
                  <ListBusinessDetailsAvatar>
                    <Avatar alt={person.name} src={person.photo_url} />
                  </ListBusinessDetailsAvatar>
                  <ListBusinessDetailsText
                    primary={person.name}
                    primaryTypographyProps={{ fontWeight: 600 }}
                    secondary={person.title}
                  />
                  <ListBusinessDetailsText
                    sx={{ textAlign: 'right' }}
                    primaryTypographyProps={{ fontSize: '12px' }}
                    secondaryTypographyProps={{ fontSize: '11px' }}
                    primary={person?.phone_numbers?.map((phone, index) => {
                      return <div key={index}>{phone?.raw_number}</div>;
                    })}
                    secondary={
                      (person?.city || '') + ', ' + (person?.country || '')
                    }
                  />
                </ListBusinessDetails>
              );
            })} */}
              </List>
            </div>
            <div className="mt-3 mb-2">
              <Button
                variant="contained"
                color="primary"
                onClick={handleInitiateTransfer}
                fullWidth
                disableElevation
                sx={{
                  mt: 2,
                  ml: 1,
                }}
                label="Envoyer en qualification"
                gap={2}
              />
            </div>
          </>
        )}
      </div>
    </DrawerSide>
  );
};

export default LeadDetails;
