import React from 'react';
import { useSelector } from 'react-redux';
import chroma from 'chroma-js';
import AvatarMUI from '@mui/material/Avatar';
import { Badge, Tooltip } from '@mui/material';
import { motion } from 'framer-motion';

const AvatarMUIWithMotion = motion(AvatarMUI);

const Avatar = React.memo(({ size, img, prefix, name, userId }) => {
  const businessPreference = useSelector((state) => state.core.businessData);
  const employees = useSelector((state) => state.core.employees);

  const currentEmployee = employees?.employees?.find(
    (employee) => employee?.id === userId
  );

  function stringToColor(name) {
    const mainColor = businessPreference?.mainColor || '#000'; // Default to '#000' if undefined
    const baseColor = chroma.valid(mainColor) ? mainColor : '#000';
    const colors = chroma.scale(['black', baseColor]).mode('lch').colors(19);

    let hash = 0;
    if (name) {
      // Check if name is not undefined
      for (let i = 0; i < name?.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
    }
    const index = Math.abs(hash) % colors?.length;
    return colors[index];
  }

  function stringAvatar(name) {
    if (!name) return {}; // Return an empty object if name is undefined
    const color = stringToColor(name);
    return {
      sx: {
        bgcolor: color,
        backgroundImage: `radial-gradient(circle, ${chroma(color).brighten(
          0.5
        )} 0%, ${color} 30%, ${chroma(color).darken(0.7)} 100%)`,
        maxWidth: `${size === 'xsm' ? '20px' : '30px !important'}`,
        maxHeight: `${size === 'xsm' ? '20px' : '30px !important'}`,
        fontSize: `${size === 'xsm' ? '0.6rem' : '0.8rem !important'}`,
        borderRadius: '50%',
      },
      children: `${name?.split(' ')?.[0]?.[0]}${name?.split(' ')?.[1]?.[0]}`,
    };
  }
  const prefixColor = 'transparent';

  const statuses = [
    {
      value: 'online',
      icon: '/assets/v3/vectors/statuses/green-circle.svg',
    },
    {
      value: 'busy',
      icon: '/assets/v3/vectors/statuses/no-entry.svg',
    },
    {
      value: 'invisible',
      icon: '/assets/v3/vectors/statuses/cross-mark.svg',
    },
    {
      value: 'doNotDisturb',
      icon: '/assets/v3/vectors/statuses/laptop-computer.svg',
    },
    {
      value: 'meeting',
      icon: '/assets/v3/vectors/statuses/handshake.svg',
    },
    {
      value: 'needHelp',
      icon: '/assets/v3/vectors/statuses/folded-hands-medium-light-skin-tone.svg',
    },
    {
      value: 'away',
      icon: '/assets/v3/vectors/statuses/house-with-garden.svg',
    },
    {
      value: 'onPass',
      icon: '/assets/v3/vectors/statuses/tear-off-calendar.svg',
    },
  ];

  const status = statuses?.find(
    (status) => status.value === currentEmployee?.status
  );

  const disableStatus = currentEmployee?.status === 'invisible' || !status;

  return (
    <>
      <div>
        {img === 103120 || img === '103120' || img === undefined ? (
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <AvatarMUIWithMotion
              {...stringAvatar(name || 'N D')}
              whileHover={{ scale: 1.05, rotateX: 5, rotateY: 5, rotateZ: 5 }}
              transition={{ type: 'spring', stiffness: 300 }}
            />
          </div>
        ) : (
          <>
            <Tooltip title={name || ''}>
              <Badge
                invisible={disableStatus}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <AvatarMUI
                  sx={{
                    bgcolor: `${prefixColor}20`,
                    borderRadius: '50%',
                    maxWidth: `${size === 'xsm' ? '20px' : '30px !important'}`,
                    maxHeight: `${size === 'xsm' ? '20px' : '30px !important'}`,
                  }}
                  src={
                    typeof img === 'string' && img.startsWith('http')
                      ? img
                      : `https://storage.googleapis.com/avatars_node/${img}.png`
                  }
                  alt={name}
                />
                {!disableStatus && (
                  <span
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      width: size === 'xsm' ? '10px' : '16px',
                      fontSize: size === 'xsm' ? '7px' : '10px',
                      height: size === 'xsm' ? '11px' : '14px',
                      borderRadius: '50%',
                      padding: '1px',
                    }}
                  >
                    <img
                      src={status?.icon}
                      width={size === 'xsm' ? '9px' : '12px'}
                      style={{ marginRight: '6px' }}
                    />
                  </span>
                )}
              </Badge>
            </Tooltip>
          </>
        )}
      </div>
    </>
  );
});

export default Avatar;
