// Utilities
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '@mui/material/Input';

const CardInput = ({ updateItem, value, itemId, itemKey, type }) => {
  const { t } = useTranslation();
  const [valueInput, setValueInput] = useState('');

  useEffect(() => {
    setValueInput(value);
  }, [itemId]);

  return (
    <div>
      <Input
        value={valueInput}
        variant="standard"
        onChange={(event) => setValueInput(event.target.value)}
        disableUnderline
        key={itemId + itemKey}
        fullWidth
        placeholder={t(itemKey)}
        sx={{
          fontSize: '12px',
        }}
        margin="none"
        onBlur={(event) => updateItem(event, itemKey, itemId)}
        type={type || 'text'}
        name={itemKey}
      />
    </div>
  );
};

export default CardInput;
