//utilities
import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Avatar, Chip, Divider, ListItem } from '@mui/material';
import GeneralText from '../../stories/general-components/GeneralText';
import {
  Category,
  Favorite,
  GifBox,
  ListAltTwoTone,
  Paid,
  SwitchAccessShortcut,
} from '@mui/icons-material';

const ItemSelect = ({
  article,
  selectedArticle,
  setSelectedArticle,
  handleItemSelected,
}) => {
  const { t } = useTranslation();
  const [displayOptions, setDisplayOptions] = useState(false);

  const businessPreference = useSelector((state) => state.core.businessData);

  const selectedStyle = {
    background: `${
      businessPreference?.mainColor ? businessPreference?.mainColor : '36c2b9'
    }09`,
    border: `thin solid ${
      businessPreference?.mainColor ? businessPreference?.mainColor : '36c2b9'
    }`,
    maxHeight: '120px',
    borderRadius: '8px',
  };

  const calculatePriceRate = (price) => {
    return Number(price / 10000);
  };

  const manageSelected = (item) => {
    setSelectedArticle(item);
    if (item?.options?.length > 0) {
      setDisplayOptions(true);
    } else {
      handleItemSelected(item, null);
    }
  };

  return (
    <React.Fragment key={article?.id || article?.objectID}>
      <div className={displayOptions ? 'col-12 mb-5 px-3' : 'col-3 mb-4 px-3'}>
        <div
          className={`hover d-flex p-3`}
          style={
            (selectedArticle?.id || selectedArticle?.objectID) ===
            (article?.id || article?.objectID)
              ? selectedStyle
              : {
                  backgroundColor: '#f9f9f940',
                  borderRadius: '8px',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
                  border: 'thin solid #f9f9f9',
                }
          }
          onClick={() => manageSelected(article)}
        >
          {' '}
          <div className="mx-3">
            <Avatar
              src={article?.image_url || ''}
              size="small"
              sx={{ width: 30, height: 30, zIndex: 1 }}
              alt="img"
            />
          </div>
          <div className="col-10">
            <div
              className="d-flex justify-content-between mb-2"
              style={{ overflow: 'hidden' }}
            >
              <div>
                <GeneralText
                  size="bold"
                  fontSize="13px"
                  primary={true}
                  text={article?.name}
                />
                <GeneralText
                  size="regular"
                  fontSize="10px"
                  primary={true}
                  text={article?.sku || article?.description || '-'}
                />
              </div>
              {article?.isFeatured && <Favorite fontSize="8px" color="error" />}
            </div>
            <Divider component="div" />
            <div className="d-flex middle-content mt-2">
              <div className="d-flex middle-content">
                <Paid fontSize="8px" color="secondary" />
                <GeneralText
                  size="regular"
                  fontSize="10px"
                  classNameComponent="mb-1 mx-1"
                  primary={true}
                  text={
                    article?.duration
                      ? calculatePriceRate(article?.price)?.toFixed(2) +
                        (article?.type === 2 ? ' h' : ' $')
                      : Number(article?.price / 10000)?.toFixed(2) + ' $'
                  }
                />{' '}
              </div>
              <div className="d-flex middle-content mx-2">
                <Category fontSize="8px" color="secondary" />
                <GeneralText
                  size="regular"
                  fontSize="10px"
                  classNameComponent="mb-1 mx-1"
                  primary={true}
                  text={article?.options?.length || 0}
                />{' '}
              </div>
              <div className="d-flex middle-content mx-2">
                <ListAltTwoTone fontSize="8px" color="secondary" />
                <GeneralText
                  size="regular"
                  fontSize="10px"
                  classNameComponent="mb-1 mx-1"
                  primary={true}
                  text={
                    article?.line || article?.duration
                      ? article?.duration + ' min.'
                      : '-'
                  }
                />{' '}
              </div>
              <div className="d-flex middle-content mx-2">
                <SwitchAccessShortcut fontSize="8px" color="secondary" />
                <GeneralText
                  size="regular"
                  fontSize="10px"
                  classNameComponent="mb-1 mx-1"
                  primary={true}
                  text={article?.nbVariants || 0}
                />{' '}
              </div>
            </div>

            <div className="d-flex middle-content mt-1">
              <div style={{ display: 'flex', gap: '5px' }}>
                <Chip
                  size="small"
                  color="primary"
                  label={article?.categoryName || ''}
                  className="smallChipText"
                  sx={{ height: '16px' }}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <Chip
                  size="small"
                  color="primary"
                  variant="outlined"
                  label={
                    article?.tags?.map((tag) => tag?.name).join(', ') ||
                    t('noTags')
                  }
                  className="smallChipText mx-2"
                  sx={{ height: '16px' }}
                />
              </div>
            </div>
          </div>
        </div>
        {displayOptions && (
          <div className="mt-3">
            {selectedArticle?.options?.map((option, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-between p-3 hover"
                  onClick={() => handleItemSelected(selectedArticle, option)}
                >
                  <ListItem button divider>
                    <div>
                      <GeneralText
                        size="bold"
                        fontSize="13px"
                        primary={true}
                        text={option?.name}
                      />
                    </div>
                    <div>
                      <GeneralText
                        size="regular"
                        fontSize="13px"
                        primary={true}
                        text={Number(option?.price / 10000)?.toFixed(2) + ' $'}
                      />
                    </div>
                  </ListItem>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ItemSelect;
