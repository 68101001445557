import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';

const Qr = ({ data, setData, show }) => {
  const [facingMode, setFacingMode] = useState('environment'); // Default to rear camera

  const handleResult = (result) => {
    try {
      if (result?.text) {
        const parsedResult = JSON.parse(result.text);
        if (parsedResult?.type) {
          setData(parsedResult);
        } else {
          setData({ type: 'INVALID_QR' });
        }
      }
    } catch {
      setData({ type: 'INVALID_QR' });
    }
  };

  const handleCameraChange = (event) => {
    setFacingMode(event.target.value);
  };

  return show && !data ? (
    <div>
      <select onChange={handleCameraChange} value={facingMode}>
        <option value="environment">Rear Camera</option>
        <option value="user">Front Camera</option>
      </select>
      <QrReader onResult={handleResult} constraints={{ facingMode }} />
    </div>
  ) : null;
};

export default Qr;
