import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

//components
import MainLayoutV2 from '../../layouts/MainLayoutV2';
import Block from '../../stories/layout-components/Block';

const Marketplace = () => {
  const { t, i18n } = useTranslation();
  const currentLangCode = i18n.language;
  const dispatch = useDispatch();
  const theme = useTheme();
  const businessPreference = useSelector((state) => state.core.businessData);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://labs.pathfix.com/helper.js';
    script.setAttribute('data-user-id', businessPreference?.id);
    script.id = 'pinc.helper';
    script.setAttribute('modules', 'pinc.oauth.min');
    script.setAttribute(
      'data-public-key',
      'D8659168-B079-4851-9AAD-AC62D4159FB8'
    );

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <MainLayoutV2 pageTitle={t('marketplace')}>
      <Block height={1} heightPercentage={100} noBorder>
        <div className="p-3">
          <div
            data-oauth-ui="list-columns"
            data-oauth-ui-switches="statusOn,disconnect"
            data-oauth-icon-size="Medium"
            data-oauth-button-color={businessPreference?.mainColor}
            data-oauth-ui-providers=""
            data-oauth-ui-providerTypes=""
          ></div>
        </div>
      </Block>
    </MainLayoutV2>
  );
};

export default Marketplace;
