import React, { useState, useEffect, useCallback, useRef } from 'react';
import chroma from 'chroma-js';
import { useSelector, useDispatch } from 'react-redux';
import {
  useNavigate,
  useLocation,
  useSearchParams,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import { signOut } from 'firebase/auth';
import * as modalActions from '../redux/actions/modal-actions';
import { auth } from '../firebase';
import UserSecMenu from '../components/@generalComponents/layout/UserSecMenu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import NodeAIIcon from '../components/@generalComponents/layout/NodeAIIcon.jsx';
import * as sidebarActions from '../redux/actions/sidebar-actions';
import {
  Button,
  ButtonBase,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Popper,
  ListItemText,
  Menu,
  MenuItem,
  Badge,
} from '@mui/material';
import AllModalsRoot from '../modals';
import AllSidebarsRoot from '../sidebars';
import AllDrawersRoot from '../drawers';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import * as Icons from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { Paper, Avatar } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import DatePickerButton from '../stories/general-components/DatePickerButton';
import RangePickerButton from '../stories/general-components/RangePickerButton';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PagesNav from '../components/@generalComponents/layout/PagesNav.jsx';
import AppointmentsIcon from '../components/@generalComponents/layout/Icons/AppointmentsIcon';
import DailyManagementIcon from '../components/@generalComponents/layout/Icons/DailyManagementIcon';
import TicketIcon from '../components/@generalComponents/layout/Icons/TicketIcon';
import FormsIcon from '../components/@generalComponents/layout/Icons/FormsIcon';
import '../components/@generalComponents/layout/layout.css';
import {
  clearBusinessData,
  fetchBusinessData,
  setCurrentSection,
  setOnCall,
} from '../redux/actions-v2/coreAction.js';
import ArticleIcon from '../components/@generalComponents/layout/Icons/ArticleIcon.js';
import LocationsManagementIcon from '../components/@generalComponents/layout/Icons/LocationsManagement.js';
import nodeAxiosFirebase from '../utils/nodeAxiosFirebase.js';
import { clearAllLists } from '../redux/actions-v2/listAction.js';
import TagSelector from '../stories/general-components/TagSelector.jsx';
import DialogWindow from '../stories/general-components/DialogWindow.jsx';
import AgendaSidebar from '../sidebars/AgendaSidebar.jsx';
import LogRocket from 'logrocket';
import MainSearch from '../components/@generalComponents/layout/MainSearch/MainSearch.jsx';

const MainLayoutV2 = ({
  children,
  pageTitle,
  tabs,
  print,
  email,
  deleteItem,
  save,
  sectionTitle,
  edit,
  share,
  isFeatured,
  select,
  filters,
  formatedPath,
  editLayout,
  tags,
  settings,
  elementId,
  searchActivated,
  importCSV,
  next,
  refresh,
  add,
  previous,
  cells,
  manualIndex,
  setFilters,
  currentPage,
  convert,
  payment,
  approve,
  date,
  onChangeDate,
  onChangeDateRange,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { moduleName, moduleId, structureId } = useParams();
  const currentPath = window.location.pathname;

  const { t, i18n } = useTranslation();
  const longPressTimeout = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [tagsValue, setTagsValue] = useState(tags);
  const [longPress, setLongPress] = useState(false);
  const [anchorElSelect, setAnchorElSelect] = useState(null);
  const [openSelect, setOpenSelect] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [currentSelect, setCurrentSelect] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const businessesStorage = sessionStorage.getItem('businesses');
  const businesses = JSON.parse(businessesStorage);
  const [dialogOpenCells, setDialogOpenCells] = useState(false);
  const [taskOpen, setTaskOpen] = useState(false);
  const [slots, setSlots] = useState([]);

  const isDarkMode = theme.palette.mode === 'dark';

  const softwareVersion = process.env.REACT_APP_VERSION;

  const businessPreference = useSelector((state) => state.core.businessData);
  const activeMenu = useSelector((state) => state.core.activeMenu);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const activeStructure = businessStructure?.structures?.find(
    (structure) => structure.id === structureId
  );

  const currentLanguage = i18n.language;
  const businessModules = businessStructure?.modules;

  const getModuleName = () => {
    const module = businessModules?.find((m) => m.id === moduleId);

    if (pageTitle) {
      return pageTitle;
    }

    return module?.name;
  };

  const handleLogout = async (event) => {
    event.preventDefault();

    setTimeout(() => {
      continueLogout();
    }, 600);
  };

  const continueLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Logout failed:', error);
    }
    localStorage.removeItem('activeLocation');
    sessionStorage.clear();
    localStorage.removeItem('section');
    navigate('/signin');
  };

  const currentUser = useSelector((state) => state.core.user);

  const [dateRange, setDateRange] = useState();

  const handleDateRangeChange = (newDateRange) => {
    setSearchParams(searchParams);
    if (onChangeDateRange?.rangeDates === 'single') {
      setDateRange([newDateRange[0], newDateRange[0]]);
      searchParams.set('startDate', newDateRange[0]?.format('YYYY-MM-DD'));
      searchParams.set('endDate', newDateRange[0]?.format('YYYY-MM-DD'));
      onChangeDateRange?.action &&
        onChangeDateRange.action([newDateRange[0], newDateRange[0]]);
    } else if (onChangeDateRange?.rangeDates !== 'none') {
      setDateRange(newDateRange);
      searchParams.set('startDate', newDateRange[0]?.format('YYYY-MM-DD'));
      searchParams.set('endDate', newDateRange[1]?.format('YYYY-MM-DD'));
      onChangeDateRange?.action && onChangeDateRange.action(newDateRange);
    }
  };

  const [activeIndex, setActiveIndex] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return parseInt(searchParams.get('tab')) || 0;
  });

  const updateTabIndexInURL = (index) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('tab', index);
    navigate(`${pathname}?${searchParams.toString()}`);
  };

  const handleTabChange = (event, id) => {
    setActiveIndex(id);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const tab = parseInt(searchParams.get('tab'));
    if (tab && tab !== activeIndex) {
      setActiveIndex(tab);
    }
  }, []);

  const handleSelect = (item) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('selection', item?.value);
    navigate(`${pathname}?${searchParams.toString()}`);
    setCurrentSelect(item);
    setAnchorElSelect(null);
    setOpenSelect(false);
  };

  const handleCloseSelect = () => {
    setAnchorElSelect(null);
    setOpenSelect(false);
  };

  const handleOpenSelect = () => {
    setAnchorElSelect(anchorElSelect);
    setOpenSelect(true);
  };

  useEffect(() => {
    updateTabIndexInURL(activeIndex);
  }, [activeIndex]);

  const open = Boolean(anchorEl);
  const id = open ? 'tag-popper' : undefined;

  const currentSectionState = useSelector((state) => state.core.currentSection);

  const handleIsFeatured = async () => {
    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `updateFieldV2`,
        body: {
          documentId: elementId,
          elementPath: formatedPath || 'cards',
          key: 'isFeatured',
          value: !isFeatured,
        },
      });
    } catch (error) {
      console.error('Error set isFeatured', error);
    }
  };

  const openAIModal = () => {
    dispatch(
      sidebarActions.nodeAiSidebar({
        show: true,
        isOpen: true,
      })
    );
  };

  const handleTags = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleTagsChange = async (event, newValue) => {
    setTagsValue(newValue);
    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `updateFieldV2`,
        body: {
          documentId: elementId,
          elementPath: formatedPath || 'cards',
          key: 'tags',
          value: newValue,
        },
      });
    } catch (error) {
      console.error('Error set tags', error);
    }
  };

  LogRocket.init('hpp7xp/node');

  if (currentUser) {
    LogRocket.identify(currentUser?.uid, {
      name: currentUser?.displayName,
      email: currentUser?.email,
      subscriptionType: businessPreference?.formula,
    });
  }

  const handleSectionChange = useCallback((section) => {
    dispatch(setCurrentSection(section));
  }, []);

  const handleBusinessSelect = (business) => {
    localStorage.setItem('section', 'OPERATIONS');
    dispatch(setOnCall(false));
    dispatch(setCurrentSection('OPERATIONS'));
    dispatch(clearAllLists());
    dispatch(clearBusinessData());
    sessionStorage.setItem('businessToken', business?.token);
    localStorage.setItem('businessId', business?.businessId);
    dispatch(fetchBusinessData(business?.businessId, t, currentLanguage));
    setDialogOpen(false);
    navigate('/app/dashboard');
  };

  const handleChangeBusiness = () => {
    setDialogOpen(true);
  };

  useEffect(() => {
    dispatch(
      setCurrentSection(localStorage.getItem('section') || 'OPERATIONS')
    );
  }, []);

  const resolveMenuIcon = (menuName) => {
    switch (menuName) {
      case 'OPERATIONS':
        return <TicketIcon />;
      case 'FINANCES':
        return <FormsIcon />;
      case 'NODE':
        return <DailyManagementIcon />;
      case 'TOOLS':
        return <LocationsManagementIcon />;
      case 'MARKETING':
        return <ArticleIcon />;
      case 'SETTINGS':
        return <AppointmentsIcon />;
      default:
        return <LocationsManagementIcon />;
    }
  };

  const mainColor = businessPreference?.secColor || '#000000';
  const lightColor = chroma(mainColor).brighten(0.5).hex();

  const windowHeight = window.innerHeight;
  const contentHeight = windowHeight - 76;

  const openSideTasksAgenda = () => {
    setTaskOpen(!taskOpen);
  };

  const openCells = () => {
    setDialogOpenCells(true);
  };

  const openModalScan = () => {
    dispatch(
      modalActions.modalScan({
        isOpen: true,
      })
    );
  };

  const handleButtonPress = (e) => {
    setLongPress(false);
    longPressTimeout.current = setTimeout(() => {
      setLongPress(true);
      setMenuAnchor(e.target);
    }, 750);
  };

  const handleButtonRelease = () => {
    clearTimeout(longPressTimeout.current);
    if (!longPress) {
      navigateBack();
    }
  };

  const history = localStorage.getItem('history')
    ? JSON.parse(localStorage.getItem('history'))
    : [];

  const navigateBack = () => {
    const history = localStorage.getItem('history')
      ? JSON.parse(localStorage.getItem('history'))
      : [];
    const lastElementMinusOne = history[history?.length - 2];
    navigate(lastElementMinusOne?.url || lastElementMinusOne?.pathname);
  };

  const filtredHistory = history.filter(
    (loc) => loc.businessId === businessPreference?.id
  );

  const handleCellSelect = (cell) => {};

  const cellsFormatted = cells?.[0]?.ids?.map((cell) => {
    // check if ends with mondaycom or trello or quickbooks or vln (ex 6718566991mondaycom)
    const cellId = cell.toString();
    let id;
    let name;

    if (cell.toString().endsWith('mondaycom')) {
      //split end to extract id
      id = cellId.split('mondaycom')[0];
      name = 'mondaycom';
    } else if (cell.toString().endsWith('trello')) {
      id = cellId.split('trello')[0];
      name = 'trello';
    } else if (cell.toString().endsWith('quickbooks')) {
      id = cellId.split('quickbooks')[0];
      name = 'quickbooks';
    } else if (cell.toString().endsWith('vln')) {
      id = cellId.split('vln')[0];
      name = 'vln';
    } else {
      id = cellId;
    }

    return {
      id: id,
      name: name,
    };
  });

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <DialogWindow
          title={t('switchAccount')}
          open={dialogOpen}
          size={'medium'}
          width={'medium'}
          onClose={() => setDialogOpen(false)}
        >
          <div>
            <div className="row">
              {businesses &&
                businesses?.map((business, idx) => (
                  <div
                    key={idx}
                    className="col-3 hover align-c p-1"
                    onClick={() => handleBusinessSelect(business)}
                  >
                    <div>
                      <img
                        style={{ width: '30px', height: '30px' }}
                        src={`https://storage.googleapis.com/node-business-logos/${business?.businessId}.png`}
                      />
                    </div>
                    <ListItemText
                      primary={business?.name}
                      primaryTypographyProps={{
                        fontWeight: 500,
                        color: isDarkMode ? '#FFF' : '#000',
                        fontSize: '10px',
                      }}
                      secondary={business?.city || '-'}
                      secondaryTypographyProps={{
                        variant: 'caption',
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        </DialogWindow>

        <DialogWindow
          title={t('cells')}
          open={dialogOpenCells}
          size={'medium'}
          width={'medium'}
          onClose={() => setDialogOpenCells(false)}
        >
          <div>
            <List>
              {cellsFormatted &&
                cellsFormatted?.map((cell, idx) => (
                  <ListItem
                    key={idx}
                    divider
                    onClick={() => handleCellSelect(cell)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{ width: '30px', height: '30px' }}
                        src={`/assets/v3/connectors/${cell?.name}.png`}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={cell?.id}
                      primaryTypographyProps={{
                        fontWeight: 600,
                        color: isDarkMode ? '#FFF' : '#000',
                      }}
                    />
                  </ListItem>
                ))}
            </List>
          </div>
        </DialogWindow>

        <I18nextProvider i18n={i18n}>
          <Helmet>
            <title>
              {`Node -  ${getModuleName(pageTitle, currentLanguage)}` || 'Node'}
            </title>
            <meta name="robots" content="noindex" />
          </Helmet>
          <AllModalsRoot />

          <Grid
            sx={{
              overflow: 'hidden',
              position: 'relative',
              height: '100vh',
            }}
            container
          >
            {(businessPreference?.formula === 'rise' ||
              businessPreference?.formula === 'trial') && (
              <div
                style={{
                  zIndex: 1000,
                  position: 'absolute',
                  bottom: 10,
                  right: 10,
                }}
              >
                <IconButton
                  sx={{ backgroundColor: '#FFF', padding: '8px', boxShadow: 6 }}
                  onClick={openAIModal}
                >
                  <NodeAIIcon size={30} />
                </IconButton>
              </div>
            )}
            <div
              style={{
                zIndex: 0,
                position: 'absolute',
                width: '100%',
                height: '100vh',
                backgroundColor: isDarkMode ? '#00000080' : '#FFFFFF',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '900px',
                  marginTop: '-580px',
                  borderRadius: '50%',
                  background: `radial-gradient(circle, ${lightColor}08, ${lightColor}06)`,
                  filter: 'blur(25px)',
                }}
              ></div>
            </div>
            <Grid item>
              <Paper elevation={0}>
                <AppBar
                  elevation={0}
                  color="light"
                  sx={{
                    height: '100vh',
                    minWidth: isTablet ? '48px' : '64px',
                    maxWidth: isTablet ? '48px' : '64px',
                    backgroundColor: businessPreference?.mainColor,
                    top: 0,
                    left: 0,
                    paddingTop: '20px',
                    textAlign: 'left',
                  }}
                >
                  <div className="align-c mb-4"></div>
                  {activeMenu?.length > 0 &&
                    activeMenu?.map((menu, idx) => (
                      <div className="mt-2" key={idx}>
                        {' '}
                        <Button
                          sx={{
                            maxWidth: isTablet ? '48px' : '64px',
                            minWidth: isTablet ? '48px' : '64px',
                            borderRadius: '0px',
                            backgroundColor:
                              currentSectionState === menu?.name
                                ? '#FFF'
                                : 'transparent',
                          }}
                          onClick={() => handleSectionChange(menu?.name)}
                        >
                          <div>
                            <div>
                              <svg
                                preserveAspectRatio="xMidYMid meet"
                                style={{
                                  height: isTablet ? '19px' : '22px',
                                  width: isTablet ? '19px' : '22px',
                                }}
                              >
                                {resolveMenuIcon(menu?.name)}
                              </svg>
                            </div>
                            {!isTablet && (
                              <div>
                                <Typography
                                  variant="body1"
                                  fontSize="7px"
                                  color={
                                    currentSectionState === menu?.name
                                      ? '#000'
                                      : '#FFF'
                                  }
                                  style={{ fontWeight: 400 }}
                                >
                                  {menu['name_' + currentLanguage] ||
                                    menu?.name}
                                </Typography>
                              </div>
                            )}
                          </div>
                        </Button>
                      </div>
                    ))}
                  <img
                    src="/assets/v3/img/icon-node-grey.png"
                    width={300}
                    alt="Node"
                    style={{
                      rotate: '270deg',
                      bottom: 95,
                      position: 'absolute',
                      opacity: 0.12,
                      left: -143,
                    }}
                  />
                  <div>
                    <div
                      style={{
                        bottom: 10,
                        marginLeft: '5px',
                        zIndex: 1000,
                        position: 'absolute',
                      }}
                    >
                      <Tooltip placement="right" title={t('scan')}>
                        <IconButton
                          sx={{
                            width: '55px',
                            height: '55px',
                            maxHeight: '55px',
                            maxWidth: '55px',
                            minWidth: '55px',
                            borderRadius: '50%',
                            padding: '0px',
                            marginRight: '5px',
                          }}
                          size="medium"
                          onClick={openModalScan}
                        >
                          <Icons.QrCodeScannerOutlined
                            sx={{
                              fontSize: '30px',
                            }}
                            color={'white'}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="right" title={t('logout')}>
                        <IconButton
                          sx={{
                            width: '55px',
                            height: '55px',
                            maxHeight: '55px',
                            maxWidth: '55px',
                            minWidth: '55px',
                            borderRadius: '50%',
                            padding: '0px',
                            margin: '0px',
                          }}
                          size="medium"
                          onClick={handleLogout}
                        >
                          <Icons.ExitToApp
                            sx={{
                              fontSize: '30px',
                            }}
                            color={'white'}
                          />
                        </IconButton>
                      </Tooltip>
                      <UserSecMenu />
                    </div>
                  </div>
                </AppBar>
              </Paper>
            </Grid>
            <Grid
              sx={{
                paddingLeft: isTablet ? '48px' : '64px',
                borderRight: '1px solid rgba(0,0,0,0.1)',
                position: 'relative',
              }}
              xs={2.5}
              sm={1}
              md={2.5}
              lg={2.5}
              xl={2.5}
              item
            >
              <Paper elevation={0}>
                <div>
                  <div className="align-c">
                    {businessPreference?.id && (
                      <div
                        className="mb-1 mt-2"
                        style={{
                          height: '6vh',
                          zIndex: 1000,
                          minHeight: isTablet ? '45px' : '65px',
                          position: 'relative',
                        }}
                        onClick={handleChangeBusiness}
                      >
                        <img
                          src={`https://storage.googleapis.com/node-business-logos/${businessPreference?.id}.png`}
                          height={isTablet ? '35px' : '45px'}
                          width={isTablet ? '35px' : '45px'}
                          style={{ borderRadius: '50%' }}
                          className="hover mt-2"
                        />
                      </div>
                    )}

                    <div
                      style={{
                        height: '88vh',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        position: 'relative',
                        zIndex: 1000,
                      }}
                    >
                      <PagesNav
                        isTablet={isTablet}
                        slots={slots}
                        tasksOpen={taskOpen}
                        openSideTasksAgenda={openSideTasksAgenda}
                      />
                    </div>
                    <div
                      style={{
                        position: 'relative',
                      }}
                    >
                      <Typography
                        variant="caption"
                        fontSize={isTablet ? '8px' : '10px'}
                        color="#00000085"
                        lineHeight="1"
                        display="block"
                        fontWeight={600}
                        gutterBottom
                      >
                        Node™
                      </Typography>
                      <Typography
                        variant="caption"
                        fontSize={isTablet ? '6.5px' : '7px'}
                        color="#00000085"
                        lineHeight="1"
                        display="block"
                        gutterBottom
                      >
                        Version {softwareVersion} - Tous droits réservés.
                      </Typography>
                    </div>
                  </div>
                </div>
              </Paper>
            </Grid>
            {taskOpen && (
              <Grid
                sx={{
                  borderRight: '1px solid rgba(0,0,0,0.1)',
                  position: 'relative',
                }}
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                item
              >
                <Paper
                  sx={{
                    backgroundColor: 'transparent',
                    height: '98vh',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    position: 'relative',
                  }}
                  elevation={0}
                >
                  <AgendaSidebar slots={slots} setSlots={setSlots} />
                </Paper>
              </Grid>
            )}
            <Grid
              xs={8}
              sm={taskOpen ? 7 : 11}
              md={taskOpen ? 7 : 9.5}
              lg={taskOpen ? 7 : 9.5}
              xl={taskOpen ? 7 : 9.5}
              item
              id="main-content"
            >
              <Paper
                elevation={0}
                id="main-content-paper"
                sx={{
                  zIndex: 1000,
                  height: '100vh',
                  backgroundColor: 'transparent',
                }}
              >
                {/* {!isMobile && (
                  <Paper
                    sx={{
                      borderBottom: '1px solid rgba(0,0,0,0.1)',
                      position: 'relative',
                      backgroundColor: 'transparent',
                    }}
                    elevation={0}
                  >
                    <div
                      style={{
                        height: '60px',
                      }}
                      className="col-md-12"
                    >
                      <TopNavbar
                        isMobile={isMobile}
                        titlePage={getModuleName(pageTitle, currentLanguage)}
                      />
                    </div>
                  </Paper>
                )} */}

                <div className="mt-1">
                  <Paper elevation={0}>
                    <div
                      style={{
                        borderBottom: '1px solid rgba(0,0,0,0.1)',
                        position: 'relative',
                        height: '55px',
                        maxHeight: '55px',
                        minHeight: '55px',
                        // borderRadius: '12px',
                        backgroundColor: '#FFFFFF80',
                        // boxShadow: '0px 0px 8px  0px rgba(0, 0, 0, 0.07)',
                      }}
                    >
                      <Toolbar
                        sx={{
                          justifyContent: 'space-between',
                          color: isDarkMode ? '#FFF' : '#000000',
                          marginLeft: '-8px',
                        }}
                      >
                        <Box
                          sx={{
                            paddingLeft: '10px',
                            marginTop: '-15px',
                            display: 'flex',
                            alignContent: 'left',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            borderRadius: '10px',
                          }}
                          flex={4}
                        >
                          {businessPreference?.preferences?.backNav && (
                            <div>
                              <Menu
                                anchorEl={menuAnchor}
                                open={Boolean(menuAnchor)}
                                onClose={() => setMenuAnchor(null)}
                                elevation={3}
                                sx={{
                                  marginTop: '18px',
                                  marginLeft: '-14px',
                                  '& .MuiPaper-root': {
                                    borderRadius: '12px',
                                  },
                                }}
                              >
                                {filtredHistory?.map((loc, index) => (
                                  <MenuItem
                                    divider
                                    dense
                                    key={index}
                                    onClick={() => navigate(loc?.pathname)}
                                  >
                                    <div>
                                      <Typography
                                        variant="body1"
                                        fontWeight={600}
                                        fontSize="12px"
                                        style={{
                                          color: isDarkMode ? '#fff' : '#000',
                                        }}
                                      >
                                        {loc?.name}
                                      </Typography>

                                      <Typography
                                        variant="body1"
                                        fontWeight={400}
                                        fontSize="11px"
                                        style={{
                                          color: isDarkMode
                                            ? '#ffffff60'
                                            : '#00000060',
                                        }}
                                      >
                                        {loc?.moduleName}
                                      </Typography>
                                    </div>
                                  </MenuItem>
                                ))}
                              </Menu>
                              <Tooltip title={t('navigateBack')}>
                                <IconButton
                                  sx={{
                                    width: '35px',
                                    height: '35px',
                                    maxHeight: '35px',
                                    maxWidth: '35px',
                                    minWidth: '35px',
                                    borderRadius: '50%',
                                    padding: '0px',
                                    marginLeft: '-10px',
                                  }}
                                  size="medium"
                                  onMouseDown={handleButtonPress}
                                  onMouseUp={handleButtonRelease}
                                  onTouchStart={handleButtonPress}
                                  onTouchEnd={handleButtonRelease}
                                >
                                  <Icons.ArrowBack
                                    sx={{
                                      fontSize: '22px',
                                    }}
                                    color={'black'}
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}

                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}
                            flex={6}
                          >
                            <Tooltip
                              title={getModuleName(pageTitle, currentLanguage)}
                            >
                              <span>
                                <Typography
                                  sx={{
                                    mr: 2,
                                    maxWidth: '25vh',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                  fontSize={isTablet ? '13px' : '16px'}
                                  fontWeight={600}
                                >
                                  {editLayout?.display
                                    ? t('editMode')
                                    : getModuleName(pageTitle, currentLanguage)}
                                </Typography>
                              </span>
                            </Tooltip>
                            {!isMobile && (
                              <div className="col-12 col-md-5">
                                {!currentPath?.startsWith('/app/element') &&
                                  searchActivated && (
                                    <MainSearch
                                      userLogged={true}
                                      userType="business"
                                      structureCollection={
                                        activeStructure?.collectionField
                                      }
                                    />
                                  )}
                              </div>
                            )}
                          </Box>
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}
                            flex={6}
                          >
                            {cells && (
                              <motion.div whileHover={{ scale: 1.2 }}>
                                <Tooltip title={t('cells')}>
                                  <span>
                                    <IconButton
                                      size="large"
                                      edge="start"
                                      color="inherit"
                                      onClick={openCells}
                                      sx={{ ml: 0.4 }}
                                    >
                                      <Badge
                                        badgeContent={cellsFormatted?.length}
                                        color="secondary"
                                      >
                                        <Icons.HubOutlined />
                                      </Badge>
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </motion.div>
                            )}
                            {share && (
                              <motion.div whileHover={{ scale: 1.2 }}>
                                <Tooltip title={t('sharableLink')}>
                                  <span>
                                    <IconButton
                                      size="large"
                                      edge="start"
                                      color="inherit"
                                      onClick={share}
                                      sx={{ ml: 0.4 }}
                                    >
                                      <Icons.LinkOutlined />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </motion.div>
                            )}
                            {refresh && !editLayout?.display && (
                              <motion.div
                                whileHover={{ scale: 1.2, rotate: 90 }}
                              >
                                <Tooltip title={t('refresh')}>
                                  <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    onClick={refresh}
                                    sx={{ ml: 0.5 }}
                                  >
                                    <Icons.LoopOutlined />
                                  </IconButton>
                                </Tooltip>
                              </motion.div>
                            )}
                            {importCSV && !editLayout?.display && (
                              <motion.div
                                whileHover={{ scale: 1.2, rotate: 90 }}
                              >
                                <Tooltip title={t('import')}>
                                  <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    onClick={importCSV}
                                    sx={{ ml: 0.5 }}
                                  >
                                    <Icons.UploadFileOutlined />
                                  </IconButton>
                                </Tooltip>
                              </motion.div>
                            )}
                            {filters && setFilters && !editLayout?.display && (
                              <motion.div
                                whileHover={{ scale: 1.2, rotate: 90 }}
                              >
                                <Tooltip title={t('filters')}>
                                  <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    onClick={setFilters}
                                    sx={{ ml: 0.5 }}
                                  >
                                    <Icons.TuneOutlined />
                                  </IconButton>
                                </Tooltip>
                              </motion.div>
                            )}{' '}
                            {add && !editLayout?.display && (
                              <motion.div whileHover={{ scale: 1.2 }}>
                                <Tooltip title={t('create')}>
                                  <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    onClick={add}
                                    sx={{ ml: 0.4 }}
                                  >
                                    <Icons.Add />
                                  </IconButton>
                                </Tooltip>
                              </motion.div>
                            )}
                            {edit && !editLayout?.display && (
                              <motion.div whileHover={{ scale: 1.2 }}>
                                <Tooltip title={t('edit')}>
                                  <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    onClick={edit}
                                    sx={{ ml: 0.5 }}
                                  >
                                    <Icons.EditOutlined />
                                  </IconButton>
                                </Tooltip>
                              </motion.div>
                            )}
                            {save && !editLayout?.display && (
                              <motion.div whileHover={{ scale: 1.2 }}>
                                <Tooltip title={t('save')}>
                                  <IconButton
                                    size="medium"
                                    edge="start"
                                    color="inherit"
                                    onClick={save}
                                    sx={{ ml: 0.5 }}
                                  >
                                    <Icons.SaveOutlined />
                                  </IconButton>
                                </Tooltip>
                              </motion.div>
                            )}
                            {editLayout?.display && editLayout?.addBlock && (
                              <motion.div whileHover={{ scale: 1.2 }}>
                                <Tooltip title={t('addBlock')}>
                                  <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    onClick={editLayout?.addBlock}
                                    sx={{ ml: 0.4 }}
                                  >
                                    <Icons.AddBoxOutlined />
                                  </IconButton>
                                </Tooltip>
                              </motion.div>
                            )}
                            {previous && !editLayout?.display && (
                              <Tooltip title={t('previous')}>
                                <span>
                                  <IconButton
                                    size="large"
                                    disabled={currentPage === 1}
                                    edge="start"
                                    color="inherit"
                                    onClick={previous}
                                    sx={{ ml: 0.4 }}
                                  >
                                    <Icons.ChevronLeftOutlined />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            )}
                            {currentPage && !editLayout?.display && (
                              <Typography
                                variant="subtitle1"
                                component="div"
                                fontWeight={600}
                              >
                                {currentPage}
                              </Typography>
                            )}
                            {next && !editLayout?.display && (
                              <Tooltip title={t('next')}>
                                <span>
                                  <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    onClick={next}
                                    disabled={next === 'disabled'}
                                    sx={{ ml: 0.4 }}
                                  >
                                    <Icons.ChevronRightOutlined />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            )}
                            {deleteItem && !editLayout?.display && (
                              <motion.div whileHover={{ scale: 1.2 }}>
                                <Tooltip title={t('delete')}>
                                  <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    onClick={deleteItem}
                                    sx={{ ml: 0.5 }}
                                  >
                                    <DeleteOutlineOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              </motion.div>
                            )}
                            <Divider
                              component="div"
                              orientation="vertical"
                              flexItem
                              sx={{
                                marginLeft: '15px',
                                height: '30px',
                                marginTop: '8px',
                              }}
                            />
                            {select && (
                              <div id="select">
                                <ButtonBase
                                  sx={{
                                    backgroundColor: 'success',
                                    color: 'inherit',
                                    fontWeight: 500,
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    paddingTop: '3px',
                                    fontSize: '13.5px',
                                  }}
                                  onClick={handleOpenSelect}
                                >
                                  {currentSelect?.label || t('select')}
                                </ButtonBase>

                                <Menu
                                  anchorEl={anchorElSelect}
                                  open={openSelect}
                                  onClose={handleCloseSelect}
                                  PaperProps={{
                                    style: {
                                      maxHeight: 400,
                                      width: 200,
                                    },
                                  }}
                                >
                                  {select?.map((item, idx) => (
                                    <MenuItem
                                      key={idx}
                                      onClick={() => handleSelect(item)}
                                    >
                                      {item?.label}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </div>
                            )}
                            {payment && !editLayout?.display && (
                              <motion.div whileHover={{ scale: 1.2 }}>
                                <Tooltip title={t('addPayment')}>
                                  <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    onClick={payment}
                                    sx={{ ml: 0.5 }}
                                  >
                                    <MonetizationOnOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              </motion.div>
                            )}
                            {email && !editLayout?.display && (
                              <motion.div whileHover={{ scale: 1.2 }}>
                                <Tooltip title={t('sendEmail')}>
                                  <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    onClick={email}
                                    sx={{ ml: 0.5 }}
                                  >
                                    <EmailOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              </motion.div>
                            )}
                            {print && !editLayout?.display && (
                              <motion.div whileHover={{ scale: 1.2 }}>
                                <Tooltip title={t('print')}>
                                  <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    onClick={print}
                                    sx={{ ml: 0.5 }}
                                  >
                                    <LocalPrintshopOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              </motion.div>
                            )}
                            {convert?.action && !editLayout?.display && (
                              <motion.div whileHover={{ scale: 1.2 }}>
                                <Tooltip title={convert?.tooltip}>
                                  <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    onClick={convert?.action}
                                    sx={{ ml: 0.5 }}
                                  >
                                    <Icons.PriceCheckOutlined />
                                  </IconButton>
                                </Tooltip>
                              </motion.div>
                            )}
                            {elementId && !editLayout?.display && (
                              <motion.div whileHover={{ scale: 1.2 }}>
                                <Tooltip title={t('manageTags')}>
                                  <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    onClick={handleTags}
                                    sx={{ ml: 0.5 }}
                                  >
                                    {tags?.length > 0 ? (
                                      <Icons.LocalOffer color="secondary" />
                                    ) : (
                                      <Icons.LocalOfferOutlined />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </motion.div>
                            )}
                            {elementId && !editLayout?.display && (
                              <motion.div whileHover={{ scale: 1.2 }}>
                                <Tooltip title={t('isFeatured')}>
                                  <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    onClick={handleIsFeatured}
                                    sx={{ ml: 0.5 }}
                                  >
                                    {isFeatured === true ? (
                                      <Icons.FavoriteOutlined color="error" />
                                    ) : (
                                      <Icons.FavoriteBorderOutlined />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </motion.div>
                            )}
                            <Popper id={id} open={open} anchorEl={anchorEl}>
                              <div
                                style={{
                                  width: '320px',
                                  padding: '10px',
                                  borderRadius: '10px',
                                  boxShadow: '0px 0px 5px 0px #00000015',
                                  backgroundColor: isDarkMode
                                    ? '#00000080'
                                    : '#FFF',
                                }}
                              >
                                <TagSelector
                                  key={elementId}
                                  fullWidth
                                  error={null}
                                  required={false}
                                  label={t('tags')}
                                  value={tagsValue}
                                  fieldType={'tags'}
                                  onChange={handleTagsChange}
                                />
                              </div>
                            </Popper>
                            <Divider
                              component="div"
                              orientation="vertical"
                              flexItem
                              sx={{
                                height: '30px',
                                marginTop: '8px',
                              }}
                            />
                            {editLayout && (
                              <motion.div whileHover={{ scale: 1.2 }}>
                                <Tooltip title={t('editLayout')}>
                                  <IconButton
                                    size="medium"
                                    edge="start"
                                    color="inherit"
                                    onClick={editLayout?.action}
                                    sx={{ ml: 0.4 }}
                                  >
                                    {editLayout?.display ? (
                                      <Icons.SaveOutlined
                                        sx={{ padding: '1px' }}
                                      />
                                    ) : (
                                      <Icons.ViewQuiltOutlined />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </motion.div>
                            )}
                            {settings &&
                              (currentUser?.activeBusiness?.role ===
                                'SUPER-ADMIN' ||
                                currentUser?.activeBusiness?.role ===
                                  'ADMIN') && (
                                <motion.div whileHover={{ scale: 1.2 }}>
                                  <Tooltip title={t('pageParams')}>
                                    <IconButton
                                      size="medium"
                                      edge="start"
                                      color="inherit"
                                      onClick={settings}
                                      sx={{ ml: 0.4 }}
                                    >
                                      <Icons.SettingsOutlined />
                                    </IconButton>
                                  </Tooltip>
                                </motion.div>
                              )}
                          </Box>

                          <Box
                            sx={{
                              alignItems: 'center',
                              ml: 3,
                              mt: 0.5,
                            }}
                          >
                            {date && !editLayout?.display && (
                              <DatePickerButton
                                formatDensity="dense"
                                value={date}
                                onChange={onChangeDate}
                              />
                            )}
                          </Box>
                          {onChangeDateRange && !editLayout?.display && (
                            <Box
                              sx={{
                                alignItems: 'center',
                                ml: 3,
                                pt: 0.6,
                              }}
                            >
                              <RangePickerButton
                                formatDensity="dense"
                                value={dateRange}
                                type={onChangeDateRange?.rangeDates}
                                onChange={handleDateRangeChange}
                              />
                            </Box>
                          )}
                          {approve && !editLayout?.display && (
                            <ButtonBase
                              sx={{
                                borderRadius: '10px',
                                backgroundColor: 'success',
                                color: 'inherit',
                                fontWeight: 500,
                                fontSize: '12px',
                              }}
                              onClick={approve}
                            >
                              {t('sendApproval')}
                            </ButtonBase>
                          )}
                        </Box>
                        <Box
                          sx={{
                            mr: 2,
                            marginTop: '-15px',
                          }}
                          flex={1}
                        >
                          <Typography
                            variant="subtitle1"
                            component="div"
                            fontWeight={500}
                            fontSize="12px"
                          >
                            {sectionTitle || '-'}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            alignItems: 'right',
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                          flex={2}
                        >
                          <Tabs
                            sx={{
                              marginTop: '-12px',
                              height: '40px',
                              maxHeight: '40px',
                              minHeight: '40px',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              borderRadius: '10px',
                              backgroundColor:
                                tabs?.filter((tab) => tab?.label || tab?.name)
                                  ?.length > 0
                                  ? businessPreference?.mainColor + '15'
                                  : 'transparent',
                              position: 'relative',
                              overflow: 'hidden',
                              '.MuiTabs-indicator': {
                                display: 'none',
                              },
                              '&::before': {
                                content: '""',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                height: '100%',
                                width: '100%',
                                borderRadius: '4px',
                                transition: 'transform 0.3s ease-in-out',
                                zIndex: 1,
                                transform: `translateX(${
                                  (manualIndex || activeIndex) * 100
                                }%)`,
                              },
                            }}
                            value={manualIndex || activeIndex}
                            onChange={
                              editLayout?.display ? null : handleTabChange
                            }
                          >
                            {tabs &&
                              tabs
                                ?.filter((tab) => tab?.label || tab?.name)
                                ?.map((tab, index) => {
                                  return (
                                    <Tab
                                      label={
                                        isMobile ? '' : tab?.label || tab?.name
                                      }
                                      key={'tab' + tab?.label || tab?.name}
                                      style={{
                                        fontWeight:
                                          (manualIndex || activeIndex) === index
                                            ? '600'
                                            : '500',
                                        textTransform: 'capitalize',
                                        fontSize: '13px',
                                      }}
                                      sx={{
                                        position: 'relative',
                                        zIndex: 2,
                                        marginTop: '5px',
                                        minHeight: '30px',
                                        height: '30px',
                                        color:
                                          (manualIndex || activeIndex) === index
                                            ? `${businessPreference?.mainColor} !important`
                                            : '#000000 !important',
                                        backgroundColor:
                                          (manualIndex || activeIndex) === index
                                            ? '#FFFFFF'
                                            : 'transparent',
                                        boxShadow:
                                          (manualIndex || activeIndex) === index
                                            ? '0px 0px 5px 0px #00000020'
                                            : '',
                                        maxHeight: '30px',
                                        minWidth: isMobile ? '30px' : '',
                                        borderRadius: '8px',
                                        transition: 'color 0.3s ease-in-out',
                                        '&:hover': {
                                          color: '#000000',
                                        },
                                      }}
                                    />
                                  );
                                })}
                          </Tabs>
                        </Box>
                        {editLayout?.display && (
                          <motion.div whileHover={{ scale: 1.2 }}>
                            <Tooltip title={t('addTab')}>
                              <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                onClick={editLayout?.addTab}
                                sx={{ ml: 0.5 }}
                              >
                                <Icons.Add />
                              </IconButton>
                            </Tooltip>
                          </motion.div>
                        )}
                      </Toolbar>
                    </div>

                    <AllSidebarsRoot />
                    <AllDrawersRoot />

                    <div
                      style={{
                        height: contentHeight + 'px',
                        overflowY: 'scroll',
                      }}
                    >
                      {children}
                    </div>
                  </Paper>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </I18nextProvider>
      </DndProvider>
    </div>
  );
};

export default MainLayoutV2;
