import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
import { db } from '../../firebase';
import {
  addDoc,
  doc,
  updateDoc,
  getDoc,
  collection,
  serverTimestamp,
} from 'firebase/firestore';

import MainLayoutV2 from '../../layouts/MainLayoutV2';
import Block from '../../stories/layout-components/Block';
import { Button, List, ListItem, ListItemText } from '@mui/material';
import TextField from '../../stories/general-components/TextField';
import { fetchBusinessData } from '../../redux/actions-v2/coreAction';
import Blocks from '../../stories/layout-components/Block';
import Selection from '../../stories/general-components/Selection';

const SettingsEmails = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentLangCode = i18n.language;
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const businessPreference = useSelector((state) => state.core.businessData);

  const saveTemplate = async () => {
    try {
      const docRef = doc(
        db,
        'businessesOnNode',
        businessPreference.id,
        'emails',
        selectedTemplate?.id
      );
      await updateDoc(docRef, {
        name: selectedTemplate?.name,
        body: selectedTemplate?.body,
        subject: selectedTemplate?.isDefault ? selectedTemplate?.subject : null,
        isProject: selectedTemplate?.isProject,
        isInvoiced: selectedTemplate?.isInvoiced,
        lang: selectedTemplate?.lang,
        lastUpdate: serverTimestamp(),
        type: 'cards',
      });
      toast.success(t('saved'));
      dispatch(fetchBusinessData(businessPreference.id, t));
    } catch (error) {
      toast.error(t('error'));
    }
  };

  const addTemplate = async () => {
    try {
      const collectionRef = collection(
        db,
        'businessesOnNode',
        businessPreference.id,
        'emails'
      );

      const businessRef = doc(db, 'businessesOnNode', businessPreference.id);

      const newTemplate = {
        name: '',
        body: '',
        isDefault: false,
        subject: '',
        lang: currentLangCode,
        type: 'cards',
        businessId: businessRef,
      };

      const newDoc = await addDoc(collectionRef, newTemplate);

      //get the new template + id
      const newTemplateDoc = await getDoc(newDoc);
      setSelectedTemplate({
        id: newDoc.id,
        ...newTemplateDoc.data(),
      });
    } catch (error) {
      console.error(error);
      toast.error(t('error'));
    }
  };

  const handleChanges = (value, field) => {
    setSelectedTemplate((prevTemplate) => ({
      ...prevTemplate,
      [field]: value,
    }));
  };

  const deleteTemplate = () => {};

  return (
    <MainLayoutV2
      pageTitle={t('emails')}
      add={addTemplate}
      save={selectedTemplate && saveTemplate}
      deleteItem={selectedTemplate && deleteTemplate}
    >
      <Block height={1} heightPercentage={100} noBorder>
        <div className="row">
          <div className="col-4">
            <List>
              {businessPreference?.emails
                ?.slice()
                .sort((a, b) => a?.isDefault - b?.isDefault)
                ?.map((template) => (
                  <ListItem
                    divider
                    key={template?.id}
                    button
                    onClick={() => setSelectedTemplate(template)}
                    selected={selectedTemplate?.id === template?.id}
                  >
                    <ListItemText
                      primary={template?.name}
                      primaryTypographyProps={{
                        fontSize: '12px',
                        fontWeight: 600,
                      }}
                      secondary={
                        (template?.isDefault
                          ? t('defaultModel')
                          : t('custom')) +
                        ' - ' +
                        template?.lang?.toUpperCase()
                      }
                      secondaryTypographyProps={{
                        fontSize: '10px',
                        fontWeight: 400,
                      }}
                    />
                    <ListItemText
                      sx={{
                        textAlign: 'right',
                      }}
                      primary={t(template?.type)}
                      primaryTypographyProps={{
                        fontSize: '11px',
                        fontWeight: 'medium',
                      }}
                      secondary={moment
                        .unix(
                          template?.lastUpdate?.seconds ||
                            template?.lastUpdate?._seconds
                        )
                        .format('DD MMM HH:mm')}
                      secondaryTypographyProps={{
                        fontSize: '8px',
                        fontWeight: 400,
                      }}
                    />
                  </ListItem>
                ))}
            </List>
          </div>
          <div className="col-8 px-5">
            <Blocks
              empty={!selectedTemplate}
              emptyType="select"
              noBorder
              heightPercentage={100}
            >
              <TextField
                fullWidth
                label={t('name')}
                value={selectedTemplate?.name}
                onChange={(e) => {
                  handleChanges(e.target.value, 'name');
                }}
              />
              {selectedTemplate?.isDefault && (
                <>
                  <TextField
                    fullWidth
                    label={t('subject')}
                    value={selectedTemplate?.subject}
                    onChange={(e) => {
                      handleChanges(e.target.value, 'subject');
                    }}
                  />
                </>
              )}
              <div>
                <Selection
                  value={selectedTemplate?.isInvoiced}
                  onChange={(value, id) => {
                    selectedTemplate?.isDefault
                      ? handleChanges()
                      : handleChanges(id, value);
                  }}
                  field={{
                    typeData: 'selectionNode',
                    value: 'isInvoiced',
                    required: true,
                  }}
                  selections={[
                    {
                      label: t('yes'),
                      value: true,
                      id: 'true',
                      color: businessPreference?.mainColor,
                    },
                    {
                      label: t('no'),
                      value: false,
                      id: 'false',
                      color: businessPreference?.secColor,
                    },
                  ]}
                  label={t('isInvoicedCard')}
                />
                <Selection
                  value={selectedTemplate?.isProject}
                  onChange={(value, id) => {
                    selectedTemplate?.isDefault
                      ? handleChanges()
                      : handleChanges(id, value);
                  }}
                  field={{
                    typeData: 'selectionNode',
                    value: 'isProject',
                    required: true,
                  }}
                  selections={[
                    {
                      label: t('yes'),
                      value: true,
                      id: 'true',
                      color: businessPreference?.mainColor,
                    },
                    {
                      label: t('no'),
                      value: false,
                      id: 'false',
                      color: businessPreference?.secColor,
                    },
                  ]}
                  label={t('isProjectCard')}
                />
                <Selection
                  value={selectedTemplate?.lang}
                  onChange={(value, id) => {
                    selectedTemplate?.isDefault
                      ? handleChanges()
                      : handleChanges(id, value);
                  }}
                  field={{
                    typeData: 'selectionNode',
                    value: 'lang',
                    required: true,
                  }}
                  selections={[
                    {
                      label: t('fr'),
                      value: 'fr',
                      id: 'fr',
                      color: businessPreference?.mainColor,
                    },
                    {
                      label: t('en'),
                      value: 'en',
                      id: 'en',
                      color: businessPreference?.secColor,
                    },
                  ]}
                  label={t('language')}
                />
              </div>
              <TextField
                fullWidth
                rows={6}
                multiline
                label={t('body')}
                value={selectedTemplate?.body}
                onChange={(e) => {
                  handleChanges(e.target.value, 'body');
                }}
              />
            </Blocks>
          </div>
        </div>
      </Block>
    </MainLayoutV2>
  );
};

export default SettingsEmails;
