import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  Button,
  Typography,
  Container,
  Paper,
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  InputAdornment,
  AccordionDetails,
  TextField,
  IconButton,
} from '@mui/material';
import { keyframes } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { ArrowForward, ExpandMore, Send } from '@mui/icons-material';

const HomeBusiness = ({ isMobile }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const GradientTypography = styled(Typography)(({ theme }) => ({
    background: 'linear-gradient(45deg,#200EF0  30%,  #FF4848 60%)',
    backgroundSize: '200% 200%',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: `${gradientAnimation} 20s ease infinite`,
  }));

  const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  25% { background-position: 100% 50%; }
  50% { background-position: 0% 50%; }
`;

  const handleSendEmail = () => {
    console.log('send email');
  };

  return (
    <>
      {' '}
      <div
        style={{
          zIndex: 100,
          padding: '60px',
          textAlign: 'left',
          marginTop: '50px',
          marginBottom: '60px',
          maxWidth: isMobile ? '95%' : '90%',
          marginLeft: isMobile ? '2.5%' : '5%',
        }}
        className="row"
      >
        <div
          className="hide-on-mobile"
          style={{
            width: '200px',
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            marginLeft: '320px',
            zIndex: 2,
            right: 0,
          }}
        >
          <img
            src="/assets/website/2.0/mesh-79.png"
            alt="arrow"
            className="hide-on-mobile"
            style={{
              opacity: 0.22,
              rotate: '20deg',
              width: '900px',
              marginTop: '-60px',
              marginLeft: '220px',

              borderRadius: '70px',
              right: 0,
              zIndex: 0,
            }}
          />
        </div>
        <div sx={{ position: 'relative' }} className="col-12 col-md-6">
          <Box sx={{ zIndex: 1, backgroundColor: 'transparent' }}>
            <div
              className="hide-on-mobile"
              style={{
                width: '200px',
                marginTop: '400px',
                height: '200px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',

                zIndex: 0,
              }}
            >
              <img
                src="/assets/website/2.0/mesh-78.png"
                alt="arrow"
                className="hide-on-mobile"
                style={{
                  opacity: 0.07,
                  rotate: '20deg',
                  width: '900px',
                  marginLeft: '-120px',
                  borderRadius: '70px',
                  left: 0,
                }}
              />
            </div>

            <div
              style={{
                zIndex: 2,
                position: 'relative',
              }}
            >
              <GradientTypography
                fontWeight={600}
                fontSize={48}
                variant="h1"
                mt={5}
                sx={{ zIndex: 5 }}
              >
                {t('businessesManagement')}
              </GradientTypography>

              <Typography fontSize={40} fontWeight={600} gutterBottom>
                {t('withNode')}
              </Typography>
            </div>
          </Box>

          <Typography variant="h5" gutterBottom>
            {t('homeBusinessSubTitle')}
          </Typography>
          <Typography fontSize={14} fontWeight={400} gutterBottom mt={2}>
            {t('headerSubText')}
          </Typography>
          <div style={{ gap: 1 }}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              elevation={0}
              size="large"
              onClick={() => navigate('/platform')}
              sx={{ marginTop: '20px' }}
            >
              {t('discoverNode')}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              elevation={0}
              disableElevation
              size="large"
              onClick={() => navigate('/contact')}
              sx={{ marginTop: '20px', marginLeft: isMobile ? '0px' : '20px' }}
            >
              {t('contactUs')}
            </Button>
          </div>
        </div>
        <div className="col-12 col-md-6 align-right">
          <div
            style={{
              width: '180px',
              zIndex: 10,
              fontWeight: 500,
              textAlign: 'center',
            }}
            className="glassmorphism bouncingSquare hide-on-mobile"
          >
            {t('createdTested')}
          </div>
          <img
            src="/assets/website/2.0/UserNodeA.png"
            alt="arrow"
            style={{
              width: '600px',
              position: 'absolute',
              right: 0,

              zIndex: 5,
            }}
            className="hide-on-mobile"
          />
          <img
            src="/assets/website/2.0/asset.svg"
            alt="arrow"
            className="hide-on-mobile"
            style={{
              width: '150px',
              position: 'absolute',
              left: 60,
              opacity: 0.4,
              rotate: '180deg',
              bottom: 1,
              zIndex: 1,
            }}
          />{' '}
          <img
            src="/assets/website/2.0/geo-b.svg"
            alt="arrow"
            className="hide-on-mobile"
            style={{
              width: '40px',
              position: 'absolute',
              left: 200,
              top: 20,
            }}
          />
        </div>
      </div>
      <Box
        sx={{
          marginTop: '130px',
          maxWidth: '90%',
          marginLeft: '5%',
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Paper
          elevation={0}
          sx={{
            padding: '20px',
          }}
        >
          <img
            src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da039_GoogleStartUp.png"
            alt="google"
            style={{ height: '27px', margin: '0 auto' }}
          />
        </Paper>
        <Paper
          elevation={0}
          sx={{
            padding: '20px',
          }}
        >
          <img
            src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da045_PCINode.png"
            alt="PCI"
            style={{ height: '27px', margin: '0 auto' }}
          />
        </Paper>
        <Paper
          elevation={0}
          className="hide-on-mobile"
          sx={{
            padding: '20px',
          }}
        >
          <img
            src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da035_makeNode.png"
            alt="make integromat"
            style={{ height: '29px', margin: '0 auto' }}
            className="hide-on-mobile"
          />
        </Paper>
        <Paper
          elevation={0}
          className="hide-on-mobile"
          sx={{
            padding: '20px',
          }}
        >
          <img
            src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da03e_ProductHuntNode.png"
            alt="product hunt"
            style={{ height: '27px', margin: '0 auto' }}
            className="hide-on-mobile"
          />
        </Paper>
        <Paper
          elevation={0}
          className="hide-on-mobile"
          sx={{
            padding: '20px',
          }}
        >
          <img
            src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da04a_RQNode.png"
            alt="revenu quebec"
            style={{ height: '29px', margin: '0 auto' }}
            className="hide-on-mobile"
          />
        </Paper>
      </Box>
      <Box sx={{ marginTop: '120px', maxWidth: '80%', marginLeft: '10%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} style={{ position: 'relative' }}>
            <img
              src="/assets/website/2.0/rectangle.svg"
              alt="rectangle"
              width={50}
              style={{
                position: 'absolute',
                zIndex: 1,

                marginLeft: '-8px',
                top: 0,
                left: 0,
              }}
            />
            <Paper
              elevation={0}
              sx={{
                padding: '22px',
                borderRadius: '20px',
                zIndex: 10,
                textAlign: 'left',
                backgroundColor: '#FF484807',
              }}
            >
              <Typography variant="h6" fontWeight={600} gutterBottom mt={2}>
                {t('homeBusinessTitle1')}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {t('homeBusinessSubTitle1')}
              </Typography>
              <Button
                variant="text"
                color="error"
                sx={{ marginLeft: '-7px', marginTop: '10px' }}
                onClick={() => navigate('/platform')}
              >
                {t('discover')}
                <ArrowForward sx={{ marginLeft: '4px' }} />
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} style={{ position: 'relative' }}>
            <img
              src="/assets/website/2.0/triangle.svg"
              alt="triangle"
              width={42}
              style={{
                position: 'absolute',
                zIndex: 1,
                marginLeft: '-8px',
                marginBottom: '-14px',
                bottom: 0,
                left: 30,
              }}
            />
            <Paper
              elevation={0}
              sx={{
                padding: '22px',
                borderRadius: '20px',
                textAlign: 'left',
                backgroundColor: '#200EF007',
              }}
            >
              <Typography variant="h6" fontWeight={600} gutterBottom mt={2}>
                {t('homeBusinessTitle2')}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {t('homeBusinessSubTitle2')}
              </Typography>
              <Button
                variant="text"
                sx={{
                  marginLeft: '-7px',
                  marginTop: '10px',
                  color: '#200EF0',
                }}
                onClick={() => navigate('/use-cases')}
              >
                {t('casesNode')}
                <ArrowForward sx={{ marginLeft: '4px' }} />
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} style={{ position: 'relative' }}>
            <img
              src="/assets/website/2.0/circle.svg"
              alt="circle"
              width={36}
              style={{
                position: 'absolute',
                zIndex: 1,
                marginRight: '-50px',
                bottom: 30,
                right: 30,
              }}
            />
            <Paper
              elevation={0}
              sx={{
                padding: '22px',
                borderRadius: '20px',
                textAlign: 'left',
                backgroundColor: '#C0EB1209',
              }}
            >
              <Typography variant="h6" fontWeight={600} gutterBottom mt={2}>
                {t('homeBusinessTitle3')}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {t('homeBusinessSubTitle3')}
              </Typography>
              <Button
                variant="text"
                color="success"
                sx={{ marginLeft: '-7px', marginTop: '10px' }}
                onClick={() => navigate('/approch')}
              >
                {t('howWeWork')}
                <ArrowForward sx={{ marginLeft: '4px' }} />
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          marginTop: '120px',
          minHeight: '650px',
          backgroundSize: 'fit',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          maxWidth: '90%',
          marginLeft: '5%',
          marginBottom: '200px',
        }}
      >
        {' '}
        <div style={{ zIndex: 100, paddingTop: isMobile ? '80px' : '100px' }}>
          <Typography variant="h4" gutterBottom fontWeight={600}>
            {t('theOnlyOne')}
          </Typography>
          <Typography variant="h5" gutterBottom>
            {t('homeBusinessNode')}
          </Typography>
        </div>
        <img
          src="/assets/website/2.0/layout.png"
          alt="platform"
          style={{
            borderRadius: '20px',
            width: isMobile ? '95%' : '70%',
            marginTop: '40px',
            marginBottom: isMobile ? '10px' : '100px',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: isMobile ? '' : '70px',
          minHeight: isMobile ? '400px' : '650px',
          backgroundSize: 'fit',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          maxWidth: '90%',
          marginLeft: '5%',
        }}
      >
        <div className={isMobile ? 'row' : 'row mt-5 mb-5'}>
          <div className="col-md-6 col-12 align-c ">
            <img
              src="/assets/website/2.0/manufacturer-node.jpg"
              alt="arrow"
              style={{
                width: '400px',
                borderRadius: '20px',
                marginLeft: isMobile ? '' : '16%',
                zIndex: 3,
              }}
            />
            <img
              src="/assets/website/2.0/geo-a.svg"
              alt="arrow"
              className="hide-on-mobile"
              style={{
                width: '60px',
                position: 'absolute',
                bottom: 150,
                left: 100,
              }}
            />{' '}
          </div>
          <div className="col-md-6 col-12">
            <div className="align-left">
              {' '}
              <Typography fontSize={24} fontWeight={600} mt={5}>
                {t('exploxePossibilities')}
              </Typography>
              <Typography fontSize={20} fontWeight={500}>
                {t('allInOneSoftware')}
              </Typography>
            </div>
            <div className="align-left">
              <Accordion
                sx={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  marginTop: '20px',
                }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  {' '}
                  <Typography fontSize={14} fontWeight={600}>
                    {t('dashboards')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize={14}
                      fontWeight={600}
                      gutterBottom
                    >
                      {t('dashboardsHead')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {t('dashboardsBody')}
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  marginTop: '20px',
                }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography fontSize={14} fontWeight={600}>
                    {t('operations')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize={14}
                      fontWeight={600}
                      gutterBottom
                    >
                      {t('operationsHead')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {t('operationsBody')}
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  marginTop: '20px',
                }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  {' '}
                  <Typography fontSize={14} fontWeight={600}>
                    {t('dataManagement')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize={14}
                      fontWeight={600}
                      gutterBottom
                    >
                      {t('dataManagementHead')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {t('dataManagementBody')}
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  marginTop: '20px',
                }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  {' '}
                  <Typography fontSize={14} fontWeight={600}>
                    {t('performance')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize={14}
                      fontWeight={600}
                      gutterBottom
                    >
                      {t('performanceHead')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {t('performanceBody')}
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  marginTop: '20px',
                }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography fontSize={14} fontWeight={600}>
                    {t('collaborationTools')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize={14}
                      fontWeight={600}
                      gutterBottom
                    >
                      {t('collaborationToolsHead')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {t('collaborationToolsBody')}
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </Box>
      <Box
        sx={{
          minHeight: '120px',
          backgroundSize: 'fit',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          backgroundColor: '#F5F5F5',
          padding: isMobile ? '30px' : '25px 150px 25px 150px',
          marginTop: isMobile ? '50px' : '0px',
        }}
      >
        <div className="row py-3">
          <div
            className={
              isMobile
                ? 'col-12 col-md-2 align-left mb-4 mt-2 px-4'
                : 'col-12 col-md-2 align-left mt-2'
            }
          >
            <Typography variant="p" fontSize={14} gutterBottom fontWeight={600}>
              {t('doOtherUsers') + ' '}
            </Typography>
            <Typography variant="p" fontSize={14} gutterBottom fontWeight={600}>
              {t('doOtherSub')}
            </Typography>
          </div>
          <div className="col-6 col-md-2">
            {' '}
            <img
              src="/assets/website/2.0/businesses/point-s.png"
              alt="arrow"
              style={{
                width: '100px',
                zIndex: 5,
              }}
            />
          </div>
          <div className="col-6 col-md-2 ">
            {' '}
            <img
              src="/assets/website/2.0/businesses/obox.png"
              alt="arrow"
              style={{
                width: '100px',
                zIndex: 5,
              }}
            />
          </div>
          <div className="col-6 col-md-2 ">
            {' '}
            <img
              src="/assets/website/2.0/businesses/garex.png"
              alt="arrow"
              style={{
                width: '100px',
                zIndex: 5,
              }}
            />
          </div>
          <div className="col-6 col-md-2 ">
            {' '}
            <img
              src="/assets/website/2.0/businesses/roy.png"
              alt="arrow"
              style={{
                width: '100px',
                zIndex: 5,
              }}
            />
          </div>
          <div className="col-6 col-md-2 ">
            {' '}
            <img
              src="/assets/website/2.0/businesses/packmat.png"
              alt="arrow"
              style={{
                width: '100px',
                zIndex: 5,
              }}
            />
          </div>
        </div>
      </Box>
      <Box
        sx={{
          marginTop: '170px',
          minHeight: '800px',
          backgroundSize: isMobile ? 'cover' : 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          padding: isMobile ? '25px' : '25px 150px 25px 150px',
          backgroundImage: 'url(/assets/website/2.0/bg-blue.png)',
          borderRadius: '20px',
          maxWidth: isMobile ? '90%' : '75%',
          marginLeft: isMobile ? '5%' : '10%',
        }}
      >
        <div className="row py-5">
          <div className="col-md-6 col-12 mt-5 px-5 align-left">
            <Typography fontSize={24} fontWeight={600} color="#200EF0" mt={5}>
              {t('useNode')}
            </Typography>
            <Typography fontSize={20} fontWeight={500} color="#200EF0">
              {t('oneStopShop')}
            </Typography>
            <Button
              variant="text"
              size="large"
              sx={{
                marginLeft: '-7px',
                marginTop: '30px',
                color: '#200EF0',
              }}
              onClick={() => navigate('/platform')}
            >
              {t('discover')}
              <ArrowForward sx={{ marginLeft: '4px' }} />
            </Button>
          </div>
          <div className="col-md-6 col-12 mt-5 px-3 align-left">
            <img
              src="/assets/website/2.0/overview.png"
              alt="arrow"
              style={{
                width: '560px',
                borderRadius: '20px',
                marginTop: '6%',
                zIndex: 3,
              }}
            />
          </div>
        </div>
      </Box>
      <Box
        sx={{
          marginTop: '20px',
          minHeight: '100px',
          padding: '12px',
        }}
      >
        <div className="row align-c mb-5">
          <div>
            <Typography fontSize={32} fontWeight={600}>
              {t('stayInTouch')}
            </Typography>
          </div>
          <div>
            <Typography fontSize={16} fontWeight={500} gutterBottom>
              {t('stayInTouchText')}
            </Typography>
          </div>
          <div>
            <TextField
              id="outlined-basic"
              label={t('email')}
              variant="outlined"
              size="large"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleSendEmail}
                      onMouseDown={handleSendEmail}
                      edge="end"
                    >
                      <Send />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                marginTop: '20px',
                maxWidth: '500px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '14px',
                },
              }}
            />
          </div>
        </div>
      </Box>
    </>
  );
};

export default HomeBusiness;
