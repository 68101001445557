import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@highlight-run/react';
import { Button, TextField, Typography, Container, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import Lottie from 'react-lottie';

import errorData from './lotties/error.json';
import { auth } from './firebase';

//general
import PrivateRoute from './components/PrivateRoute';
import Settings from './screens/settings/Settings';
import UsageBilling from './screens/settings/UsageBilling';
import SettingsColletions from './screens/settings/SettingsCollections';
import SettingsTeam from './screens/settings/SettingsTeam';
import SettingsEmails from './screens/settings/SettingsEmails';
import ElementDetails from './screens/mainPages/ElementDetails';
import ModuleList from './screens/mainPages/ModuleList';
import NodeMaster from './screens/specific/NodeMaster';
import Workflow from './screens/specific/Workflow';
import Marketing from './screens/specific/Marketing';
import Node from './screens/specific/Node';
import Emails from './screens/specific/Emails';
import Dashboard from './screens/mainPages/Dashboard';
import EmailsCreator from './screens/website/EmailsCreator';
import Automations from './screens/specific/Automations';
import SettingsLocations from './screens/settings/SettingsLocations';
import ListDrop from './screens/lists/ListDrop';
import Inbox from './screens/mainPages/Inbox';
import Supports from './screens/specific/Supports';
import Marketplace from './screens/specific/Marketplace';

const PrivateRoutes = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: errorData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleSignout = () => {
    auth.signOut();
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <ErrorBoundary
      customDialog={
        <Container component="main" maxWidth="xs">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            padding={3}
            mt={10}
          >
            {' '}
            <Lottie options={defaultOptions} height={180} width={180} />
            <Typography component="h2" variant="h5" fontWeight={600}>
              {t('errorBoundaryWoops')}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('errorBoundaryDesc')}
            </Typography>
            <Box component="form" width="100%" mt={3}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="title"
                label={t('feedback')}
                name="title"
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                elevation={0}
                variant="contained"
                sx={{ borderRadius: '20px', marginTop: '10px' }}
                color="primary"
                mt={3}
              >
                {t('refresh')}
              </Button>
              <Button
                onClick={handleSignout}
                fullWidth
                elevation={0}
                variant="text"
                sx={{ borderRadius: '20px', marginTop: '10px' }}
                mt={3}
              >
                {t('signout')}
              </Button>
            </Box>
          </Box>
        </Container>
      }
    >
      <Routes>
        <Route
          path="/dashboard"
          element={
            <PrivateRoute
              permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER', 'PERSONAL']}
            >
              <Dashboard />
            </PrivateRoute>
          }
        />
        [///////////////////////// General //////////////////////////]
        <Route
          path="/:collection/:moduleName/:moduleId/:structureId"
          element={
            <PrivateRoute
              permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER']}
            >
              <ModuleList />
            </PrivateRoute>
          }
        />
        <Route
          path="/:collection/:moduleName/:moduleId/:structureId/:segmentId"
          element={
            <PrivateRoute
              permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER']}
            >
              <ModuleList />
            </PrivateRoute>
          }
        />
        <Route
          path="/element/:moduleName/:structureId/:elementId"
          element={
            <PrivateRoute
              permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER']}
            >
              <ElementDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/nodes/:nodeId"
          element={
            <PrivateRoute
              permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER']}
            >
              <ListDrop />
            </PrivateRoute>
          }
        />
        [///////////////////////// Finances //////////////////////////]
        <Route
          path="/mynode/master"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <NodeMaster />
            </PrivateRoute>
          }
        />
        <Route
          path="/node/emails"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <Emails />
            </PrivateRoute>
          }
        />
        <Route
          path="/inbox"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <Inbox />
            </PrivateRoute>
          }
        />
        <Route
          path="/tools/flows"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <Workflow />
            </PrivateRoute>
          }
        />
        <Route
          path="/tools/support"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <Supports />
            </PrivateRoute>
          }
        />
        <Route
          path="/mynode/automations"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <Automations />
            </PrivateRoute>
          }
        />
        <Route
          path="/tools/presentations"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <Marketing />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/locations"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <SettingsLocations />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/tools/whiteboard"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <Whiteboard />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/mynode/page"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <Node />
            </PrivateRoute>
          }
        />
        <Route
          path="/mynode/preferences"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <Node />
            </PrivateRoute>
          }
        />
        <Route
          path="/mynode/usage"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <UsageBilling />
            </PrivateRoute>
          }
        />
        <Route
          path="/mynode/marketplace"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <Marketplace />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/structures"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <SettingsColletions />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/team"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <SettingsTeam />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/emails"
          element={
            <PrivateRoute permission={['ADMIN', 'OWNER']}>
              <SettingsEmails />
            </PrivateRoute>
          }
        />
        [///////////////////////// Extensions //////////////////////////]
        <Route path="/tools/redactor" element={<EmailsCreator />} />
      </Routes>
    </ErrorBoundary>
  );
};

export default PrivateRoutes;
