import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import * as drawerActions from '../../redux/actions-v2/drawer-actions';
import { setGeneralStatus } from '../../redux/actions-v2/coreAction';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import Blocks from '../../stories/layout-components/Block';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';

const AppointmentScheduler = ({ params, activeModule, list, isTablet }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { structureId } = useParams();
  const calendarRef = useRef(null);
  const location = useLocation();
  const locationPath = location.pathname;
  const [eventsData, setEventsData] = useState([]);

  const [passes, setCardPasses] = useState([]);

  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const structures = useSelector((state) => state.core.businessStructure);
  const structure = structures?.structures?.find((s) => s.id === structureId);
  const calendarDate = localStorage.getItem(activeModule?.id);

  const currentUser = useSelector((state) => state.core.user);

  useEffect(() => {
    if (currentUser?.activeBusiness?.role === 'EMPLOYEE') {
      let filtredList = list?.filter(
        (card) => card?.assignedToId === currentUser?.uid
      );
      setCardPasses(filtredList);
    } else {
      setCardPasses(list);
    }
  }, [list]);

  //moves calendar to custom date
  useEffect(() => {
    if (calendarRef?.current) {
      calendarRef?.current
        ?.getApi()
        ?.gotoDate(
          moment
            .unix(params?.startTimeStamp || calendarDate || '')
            .format('YYYY-MM-DD')
        );
    }
  }, [params?.startTimeStamp, list]);

  const resources = businessPreference?.locations?.map((subLocation) => {
    return {
      id: subLocation?.id,
      title: subLocation?.name,
      order: subLocation?.order,
    };
  });

  useEffect(() => {
    if (passes) {
      const mappedEvents = passes?.map((event) => {
        const start = new Date(
          (event?.startDate?.seconds || event?.startDate?._seconds) * 1000
        );
        const end = new Date(
          (event?.endDate?.seconds || event?.endDate?._seconds) * 1000
        );

        const fieldsStructure = businessStructure?.structures?.find(
          (s) => s.id === event?.structureId
        )?.fields;

        const statusField = fieldsStructure?.find(
          (field) => field?.typeData === 'status'
        );

        const color = statusField?.selections?.find(
          (selection) => selection?.value === event?.status
        )?.color;

        return {
          id: event.id,
          title: event.name || '-',
          start: start,
          end: end,
          color: color || '#696969',
          elementId: event?.dependencyId,
          documentPath: event?.documentPath,
          card: event?.dependencyId || event?.dependencyDetails?.id || {},
          resourceId: event.locationId,
          depStructureId: event?.dependencyDetails?.structureIdentifiant,
          targetProfileName:
            (event?.targetProfileAttributes?.attribute1 || '') +
            ' ' +
            (event?.targetProfileAttributes?.attribute2 || '') +
            ' ' +
            (event?.targetProfileAttributes?.attribute3 || ''),
          locationName: event?.locationName || '',
          subTitle: event?.targetName || '',
          status: event?.status || 0,
        };
      });
      setEventsData(mappedEvents);
    }
  }, [passes]);

  // Function to update Firestore document
  const updateEventInFirestore = async (
    eventId,
    newStart,
    newEnd,
    resourceId,
    path
  ) => {
    try {
      let formatedPath = path.split('/');
      let itemId = formatedPath[formatedPath.length - 1];
      formatedPath = formatedPath.filter((part) => part !== itemId).join('/');

      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'calendar-scheduler',
          type: 'pulse',
        })
      );
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `updateFieldV2`,
        body: {
          documentId: itemId,
          elementPath: formatedPath,
          key: 'datesPass',
          value: {
            startDate: newStart,
            endDate: newEnd,
          },
        },
      });
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'calendar-scheduler',
          type: 'pulse',
        })
      );
      await new Promise((resolve) => setTimeout(resolve, 2000));

      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `updateFieldV2`,
        body: {
          documentId: itemId,
          elementPath: formatedPath,
          key: 'locationIdentifiant',
          value: resourceId,
        },
      });
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const handleEventDrop = (info) => {
    // Extract the necessary information from the event object
    const eventId = info.event.id;
    const newStart = info.event.start;
    const path = info.event.extendedProps?.documentPath;
    const newEnd =
      info.event.end || new Date(info.event.start.getTime() + 60 * 60 * 1000);
    const resourceId = info.event._def?.resourceIds[0];

    // Call the function to update Firestore
    updateEventInFirestore(eventId, newStart, newEnd, resourceId, path);
  };

  const handleEventResize = (info) => {
    // Extract the necessary information from the event object
    const eventId = info.event.id;
    const newStart = info.event?.start;
    const path = info.event.extendedProps?.documentPath;
    const newEnd =
      info.event.end || new Date(info.event?.start?.getTime() + 60 * 60 * 1000);
    const resourceId = info.event._def?.resourceIds[0];

    // Call the function to update Firestore
    updateEventInFirestore(eventId, newStart, newEnd, resourceId, path);
  };

  //called when an event is clicked
  const onEventClick = (info) => {
    const extendedValues = info?.event?._def?.extendedProps;
    const collectionMatch = structures?.structures?.find(
      (s) => s.id === extendedValues?.depStructureId
    );
    const collectionField =
      collectionMatch?.collectionField || 'cardsuninvoiced';
    navigate(
      `/app/element/${collectionField}/${extendedValues?.depStructureId}/${extendedValues?.card}`
    );
  };

  // Function to render custom event content including subTitle
  const renderEventContent = (eventInfo) => {
    return (
      <>
        <div style={{ fontWeight: 400, fontSize: '0.5em' }}>
          {moment(eventInfo.event?.start).format('HH:mm')} -
          {moment(eventInfo.event?.end).format('HH:mm')}
        </div>
        <div style={{ fontWeight: 500, fontSize: '0.8em' }}>
          {eventInfo.event.title +
            ' - ' +
            eventInfo.event.extendedProps?.targetProfileName || ''}
        </div>
        {eventInfo.event.extendedProps.subTitle && (
          <div style={{ fontSize: '0.7em' }}>
            {eventInfo.event.extendedProps.subTitle}
          </div>
        )}
      </>
    );
  };

  const handleClose = () => {
    dispatch(drawerActions.createAppointment({ isDrawerOpen: false }));
  };

  const onDateSelected = async (info) => {
    const startTime = info?.date;
    const endTime = moment(info?.date).add(60, 'm').toDate();
    if (locationPath?.startsWith('/app/operations/grids')) return;

    dispatch(
      drawerActions.createAppointment({
        isDrawerOpen: true,
        selectedDate: {
          startTime: startTime,
          endTime: endTime,
        },
        defaultLocationId: info?.resource?.id,
        handleDrawerClose: handleClose,
      })
    );
  };

  return (
    <div>
      <Blocks height={1} heightPercentage={100} noBorder>
        <FullCalendar
          ref={calendarRef}
          locale={frLocale}
          initialView={
            activeModule?.list?.preferences?.initialView ||
            'resourceTimelineDay'
          }
          displayEventEnd={true}
          resources={resources}
          headerToolbar={{
            left: '',
            center: 'title',
            right:
              activeModule?.list?.preferences?.views ||
              'resourceTimelineDay,timeGridWeek',
          }}
          editable={true}
          height={isTablet ? '72vh' : '77vh'}
          aspectRatio="1.5"
          selectable={true}
          nowIndicator={true}
          startTime={structure?.element?.preferences?.startTime || '06:00:00'}
          slotDuration={
            activeModule?.list?.preferences?.slotDuration || '00:15:00'
          }
          resourceOrder={'order' || 'idx'}
          endTime={structure?.element?.preferences?.endTime || '23:00:00'}
          resourceAreaWidth={'13%'}
          eventDrop={handleEventDrop}
          eventContent={renderEventContent}
          eventResize={handleEventResize}
          eventClick={onEventClick}
          dateClick={onDateSelected}
          plugins={[
            resourceTimelinePlugin,
            interactionPlugin,
            timeGridPlugin,
            resourceTimeGridPlugin,
            listPlugin,
            dayGridPlugin,
          ]}
          events={eventsData}
        />
      </Blocks>
    </div>
  );
};

export default AppointmentScheduler;
