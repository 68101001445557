import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import WebsiteLayout from '../../layouts/WebsiteLayout';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { ArrowForward, Send } from '@mui/icons-material';

const Approch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const lang = localStorage.getItem('i18nextLng');

  const handleSendEmail = () => {
    console.log('Send email');
  };

  return (
    <WebsiteLayout full={true}>
      <Helmet>
        <title>{t('nodeTitleSEO')}</title>
        <meta name="description" content={t('nodeDescriptionSEO')} />
        <meta
          name="keywords"
          content={[
            t('businessManagement'),
            'Node',
            'usenode',
            'SaaS',
            t('software'),
            'ERP',
            'CRM',
          ]}
        />

        <link
          rel="icon"
          type="image/png"
          href={`https://storage.googleapis.com/avatars_node/nodetechnologies.png`}
          sizes="16x16"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charset="UTF-8" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:title" content={t('nodeTitleSEO')} />
        <meta property="og:description" content={t('nodeDescriptionSEO')} />
        <meta name="twitter:title" content={t('nodeTitleSEO')} />
        <link rel="canonical" href={`https://usenode.com/`} />
        <meta property="og:url" content={`https://usenode.com/`} />
      </Helmet>
      <div className="mt-5">
        <div
          className="hide-on-mobile"
          style={{
            width: '200px',
            marginTop: '400px',
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            zIndex: 0,
          }}
        >
          <img
            src="/assets/website/2.0/mesh-78.png"
            alt="arrow"
            className="hide-on-mobile"
            style={{
              opacity: 0.07,
              rotate: '20deg',
              width: '900px',
              marginLeft: '-120px',
              borderRadius: '70px',
              left: 0,
            }}
          />
        </div>
        <div
          className="hide-on-mobile"
          style={{
            width: '200px',
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            marginLeft: '320px',
            zIndex: 2,
            right: 0,
          }}
        >
          <img
            src="/assets/website/2.0/mesh-79.png"
            alt="arrow"
            className="hide-on-mobile"
            style={{
              opacity: 0.22,
              rotate: '20deg',
              width: '900px',
              marginTop: '-60px',
              marginLeft: '220px',
              borderRadius: '70px',
              right: 0,
              zIndex: 0,
            }}
          />
        </div>
        <div style={{ maxWidth: '70%', marginLeft: '15%' }} className="mt-4">
          <div className="align-c">
            <Typography
              fontSize={44}
              fontWeight={600}
              lineHeight={1.1}
              mt={5}
              py={5}
            >
              {t('scaleResults')}
            </Typography>
            <Typography fontSize={20} mt={2} fontWeight={500}>
              {t('scaleResultsText')}
            </Typography>
            <div
              style={{
                width: '100%',
                height: '160vh',
                maxHeight: '3000px',
                borderRadius: '50%',
                background:
                  'radial-gradient(circle, #C0EB1250 0%, rgba(148,187,233,0) 70%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                zIndex: 2,
              }}
            >
              {' '}
              <div
                style={{
                  width: '180px',
                  zIndex: 10,
                  position: 'absolute',
                  top: 60,
                  left: 0,
                  fontWeight: 500,
                  textAlign: 'center',
                }}
                className="glassmorphism bouncingSquare hide-on-mobile"
              >
                {t('70kdata')}
              </div>
              <div
                style={{
                  width: '180px',
                  zIndex: 10,
                  position: 'absolute',
                  top: 75,
                  right: 0,
                  marginTop: '-5vh',
                  fontWeight: 500,
                  textAlign: 'center',
                }}
                className="glassmorphism bouncingSquare-2"
              >
                {t('newEmployees')}
              </div>
              <div
                style={{
                  width: '180px',
                  zIndex: 10,
                  position: 'absolute',
                  top: 555,
                  right: isMobile ? 150 : 350,
                  marginTop: '-5vh',
                  fontWeight: 500,
                  borderRadius: '20px',
                  textAlign: 'center',
                  backgroundColor: '#C0EB12',
                }}
              >
                {'Magali B. de Node'}
              </div>
              <div
                style={{
                  zIndex: 10,
                  position: 'absolute',
                  top: 100,
                  right: isMobile ? 160 : 300,
                }}
              >
                <img
                  src="/assets/website/2.0/graph-1.png"
                  alt="arrow"
                  style={{
                    width: '180px',
                  }}
                />
              </div>
              <div
                style={{
                  zIndex: 10,
                  position: 'absolute',
                  top: 120,
                  left: 200,
                }}
              >
                <img
                  src="/assets/website/2.0/node-approch.png"
                  alt="arrow"
                  style={{
                    width: '50px',
                  }}
                />
              </div>
              <img
                src="/assets/website/2.0/entrepreneur-node.png"
                alt="arrow"
                style={{
                  width: '450px',
                  borderRadius: '20px',
                  marginTop: '-65vh',
                  position: 'absolute',
                  zIndex: 3,
                }}
              />
              <img
                src="/assets/website/2.0/asset.svg"
                alt="arrow"
                className="hide-on-mobile"
                style={{
                  width: '500px',
                  position: 'absolute',
                  left: 210,
                  opacity: 0.2,
                  top: 400,
                  rotate: '210deg',
                  zIndex: 2,
                }}
              />{' '}
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#FFF',
              borderRadius: '20px',
              width: '100%',
              height: '1000px',
              position: 'relative',
              zIndex: 8,
              marginTop: '-98vh',
            }}
          ></div>
        </div>

        <div
          style={{
            maxWidth: '80%',
            marginLeft: '10%',
            marginTop: '-840px',
            zIndex: 9,
            position: 'relative',
          }}
          className="row"
        >
          <div className="col-md-6 col-12 align-left px-4">
            <Typography fontSize={30} fontWeight={600} lineHeight={1.1} mt={5}>
              {t('mappingFlow')}
            </Typography>
            <Typography fontSize={18} fontWeight={500} lineHeight={1.1} mt={3}>
              {t('mappingFlowText')}
            </Typography>
            <Typography fontSize={12} fontWeight={400} lineHeight={1.1} mt={4}>
              {t('mappingFlowBody')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              size="large"
              sx={{ mt: 3 }}
              onClick={() => navigate('/meet-node')}
            >
              {t('planDemo')}
            </Button>
          </div>
          <div className="col-md-6 col-12 mt-4">
            <div
              style={{
                width: '90%',
                height: isMobile ? '250px' : '380px',

                borderRadius: '20px',
                background:
                  'linear-gradient(to bottom, #69696930 0%, #69696902 95%)',
              }}
            >
              {' '}
              <img
                src="/assets/website/2.0/cartography.png"
                alt="structures"
                style={{
                  width: isMobile ? '30vh' : '60vh',
                  borderRadius: '20px',
                  marginLeft: '60px',
                  maxWidth: '500px',
                  marginTop: '20px',
                  zIndex: 3,
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            maxWidth: '80%',
            marginLeft: '10%',
            marginTop: isMobile ? '80px' : '140px',
            zIndex: 9,
            position: 'relative',
          }}
          className="row"
        >
          <div className="col-md-6 col-12 mt-4">
            <div
              style={{
                width: '90%',
                height: isMobile ? '250px' : '380px',
                borderRadius: '20px',
                background:
                  'linear-gradient(to bottom, #200EF030 0%, #200EF002 95%)',
              }}
            >
              <img
                src="/assets/website/2.0/customInt.png"
                alt="integration"
                style={{
                  width: isMobile ? '30vh' : '50vh',
                  borderRadius: '20px',
                  marginLeft: '60px',
                  maxWidth: '500px',
                  marginTop: '20px',
                  zIndex: 3,
                }}
              />
            </div>
          </div>
          <div className="col-md-6 col-12 align-left px-4">
            <Typography fontSize={30} fontWeight={600} lineHeight={1.1} mt={5}>
              {t('integrationCustom')}
            </Typography>
            <Typography fontSize={18} fontWeight={500} lineHeight={1.1} mt={3}>
              {t('integrationCustomText')}
            </Typography>
            <Typography fontSize={12} fontWeight={400} lineHeight={1.1} mt={4}>
              {t('integrationCustomBody')}
            </Typography>

            <Button
              variant="text"
              color="primary"
              disableElevation
              size="large"
              sx={{ mt: 3, marginLeft: '-10px' }}
              onClick={() => navigate('/contact')}
            >
              {t('contactUs')} <ArrowForward sx={{ marginLeft: '4px' }} />
            </Button>
          </div>
        </div>
        <div
          style={{
            maxWidth: '80%',
            marginLeft: '10%',
            marginTop: '120px',
            zIndex: 9,
            position: 'relative',
          }}
          className="row"
        >
          <div className="row align-c mb-5">
            <div>
              <Typography fontSize={32} fontWeight={600}>
                {t('talkToUs')}
              </Typography>
            </div>
            <div>
              <Typography fontSize={16} fontWeight={500} gutterBottom>
                {t('talkToUsText')}
              </Typography>
            </div>
            <div className="row mt-4">
              <div className="col-md-6 col-12">
                <TextField
                  id="outlined-basic"
                  label={t('name')}
                  variant="outlined"
                  size="large"
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '14px',
                    },
                  }}
                />
              </div>
              <div className="col-md-5 col-11">
                <TextField
                  id="outlined-basic"
                  label={t('email')}
                  variant="outlined"
                  size="large"
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '14px',
                    },
                  }}
                />
              </div>
              <div className="col-md-1 col-1 mt-2">
                <IconButton
                  aria-label="send"
                  color="primary"
                  onClick={handleSendEmail}
                  sx={{
                    padding: '10px',
                    backgroundColor: '#F5f5f5',
                    borderRadius: '14px',
                  }}
                >
                  <Send />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WebsiteLayout>
  );
};

export default Approch;
