import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ModalLarge = ({
  isOpen,
  modalCloseHandler,
  title,
  fullWidth,
  maxWidth,
  children,
}) => {
  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth || 'md'}
      open={isOpen}
      sx={{ '& .MuiDialog-paper': { borderRadius: '14px' } }}
      onClose={modalCloseHandler}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div style={{ width: '55vh', maxHeight: '90vh', overflow: 'scroll' }}>
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalLarge;
