import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import WebsiteLayout from '../../layouts/WebsiteLayout';
import {
  Button,
  Typography,
  Container,
  Grid,
  Box,
  Paper,
  Chip,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Home,
  Message,
  Assignment,
  BarChart,
  Build,
  Speed,
  GroupWork,
  SwapHoriz,
  CheckCircleOutline,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { contentFr, seoContent } from './contentFr.js';
import { contentEn } from './contentEn.js';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import loadingAnimation from '../../lotties/ai.json';
import { Helmet } from 'react-helmet';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import InterestsIcon from '@mui/icons-material/Interests';
import { useTranslation } from 'react-i18next';
import BannerLeft from '../../components/website/BannerLeft';
import BothActions from '../../components/website/BothActions';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import CompareGrid from './CompareGrid';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import MobileFriendlyOutlinedIcon from '@mui/icons-material/MobileFriendlyOutlined';
import GeneralText from '../../stories/general-components/GeneralText.jsx';

const Industries = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contentName } = useParams();
  const lang = localStorage.getItem('i18nextLng');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showBottomImage, setShowBottomImage] = useState(true);
  const [showLeftImage, setShowLeftImage] = useState(true);

  const pageData =
    lang === 'fr' ? contentFr[contentName] : contentEn[contentName];
  const seoData = seoContent[contentName];

  const icons = [
    <AutoGraphIcon color="error" fontSize={'large'} />,
    <GroupsIcon color="primary" fontSize={'large'} marginBottom="10px" />,
    <InterestsIcon color="success" fontSize={'large'} marginBottom="15px" />,
  ];

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const navigateDemo = () => {
    navigate('/contact/demo');
  };

  useEffect(() => {
    if (contentName === 'ai') {
      setShowLeftImage(true);
      setShowBottomImage(true);
    } else {
      setShowLeftImage(true);
      setShowBottomImage(true);
    }
  }, [contentName]);

  const iconMapping = {
    room: <Home />,
    chat: <Message />,
    tasks: <Assignment htmlColor="#FFFFFF" />,
    analytics: <BarChart />,
    automation: <Build />,
    optimization: <Speed htmlColor="#FFFFFF" />,
    connect: <GroupWork />,
    flow: <SwapHoriz />,
    easy: <CheckCircleOutline htmlColor="#FFFFFF" />,
  };

  return (
    <WebsiteLayout>
      <Helmet>
        <title>{seoData?.title || ''} - Node</title>
        <meta name="description" content={seoData?.description || ''} />
        <meta name="keywords" content={seoData?.tags} />
      </Helmet>
      <Container>
        <div style={{ position: 'relative' }} className="d-flex">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{ marginTop: '195px' }}
                textAlign="center"
                marginTop="30%"
              >
                <Chip
                  label={t(pageData?.badge)}
                  color="primary"
                  variant="outlined"
                />
                <Typography
                  variant="h1"
                  gutterBottom
                  sx={{ marginTop: '65px' }}
                  color="black"
                  fontWeight={600}
                  fontSize={64}
                >
                  {pageData?.headerTitle}
                </Typography>
              </Box>
              <Box
                sx={{ marginTop: '20px', marginBottom: '40px' }}
                textAlign="center"
                marginTop="30%"
              >
                <Typography
                  variant="h2"
                  gutterBottom
                  sx={{ marginTop: '25px' }}
                  color="black"
                  fontWeight={500}
                  fontSize={24}
                >
                  {pageData?.subTitle}
                </Typography>
              </Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#1E1E1E',
                  color: '#FFFFFF',
                  borderRadius: '18px',
                  marginTop: '20px',
                  marginBottom: '20px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                }}
                onClick={navigateDemo}
              >
                {t('scheduleDemo')}
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className="mt-4 mb-5">
          <Grid container spacing={3} mt={12}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#F5F5F5',
                      height: '240px',
                      borderRadius: '12px',
                      textAlign: 'left',
                      overflow: 'hidden',
                    }}
                  >
                    <video
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                      src={`/assets/videos/${contentName}.mp4`}
                      autoPlay
                      loop
                      muted
                    />
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#1501F3',
                      height: '190px',
                      borderRadius: '12px',
                      padding: '30px',
                      textAlign: 'left',
                      marginTop: '30px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      color="#FFF"
                      fontWeight={500}
                      fontSize={12}
                    >
                      {pageData?.blocks[1]?.textTop}
                    </Typography>
                    <Typography
                      variant="h5"
                      gutterBottom
                      color="#FFF"
                      sx={{ marginTop: '-7px' }}
                      fontWeight={600}
                      fontSize={24}
                    >
                      {pageData?.blocks[1]?.textBottom}
                    </Typography>
                    <div className="d-flex justify-content-between mt-5">
                      <Typography
                        variant="h5"
                        gutterBottom
                        color="#FFF"
                        fontWeight={400}
                        fontSize={14}
                      >
                        {pageData?.blocks[1]?.subTitle}
                      </Typography>
                      <div>
                        <AutoAwesomeOutlinedIcon htmlColor="#FFF" />
                      </div>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#F5F5F5',
                      height: '190px',
                      borderRadius: '12px',
                      padding: '20px',
                      textAlign: 'left',
                      marginTop: '30px',
                    }}
                  >
                    <Typography
                      variant="body1"
                      gutterBottom
                      fontWeight={500}
                      sx={{ marginTop: '45px' }}
                      fontSize={12}
                    >
                      {pageData?.blocks[2]?.textBottom}
                    </Typography>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ marginTop: '-7px' }}
                      fontWeight={600}
                      fontSize={26}
                    >
                      {pageData?.blocks[2]?.text}
                    </Typography>

                    <img
                      src={`/assets/website/platform/${pageData?.blocks[2]?.img}.png`}
                      width="150px"
                      style={{
                        width: '100%',
                        marginLeft: '58px',
                        marginTop: '-135px',
                      }}
                      className="float-animation-3"
                      alt="platform"
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#333333',
                      height: '100px',
                      borderRadius: '12px',
                      padding: '20px',
                      textAlign: 'left',
                    }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      color="#FFF"
                      fontWeight={600}
                      fontSize={32}
                    >
                      {pageData?.blocks[3]?.text}
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      color="#FFF"
                      sx={{ marginTop: '-12px' }}
                      fontWeight={400}
                      fontSize={12}
                    >
                      {pageData?.blocks[3]?.subTitle}
                    </Typography>
                  </Paper>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#F5F5F5',
                      height: '180px',
                      borderRadius: '12px',
                      padding: '20px',
                      textAlign: 'left',
                      marginTop: '30px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      fontWeight={500}
                      fontSize={12}
                    >
                      {pageData?.blocks[4]?.textTop}
                    </Typography>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ marginTop: '-7px' }}
                      fontWeight={600}
                      fontSize={24}
                    >
                      {pageData?.blocks[4]?.textBottom}
                    </Typography>
                    <div className="d-flex justify-content-between mt-5">
                      <Typography
                        variant="h5"
                        gutterBottom
                        fontWeight={400}
                        fontSize={14}
                      >
                        {pageData?.blocks[4]?.subTitle}
                      </Typography>
                      <div>
                        <QrCodeScannerOutlinedIcon />
                      </div>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#F5F5F5',
                      height: '310px',
                      borderRadius: '12px',
                      textAlign: 'left',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      style={{
                        width: '100%',
                        height: '105%',
                        marginBottom: '50px',
                        objectFit: 'cover',
                        marginLeft: '46px',
                      }}
                      className="float-animation-2"
                      src={`/assets/website/platform/${pageData?.blocks[5]?.img}.png`}
                      alt="platform node"
                    />
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#F5F5F5',
                      height: '120px',
                      borderRadius: '12px',
                      textAlign: 'left',
                      marginTop: '30px',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                      src={`/assets/website/platform/${pageData?.blocks[6]?.img}.jpeg`}
                      alt="platform node"
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#c5d609',
                      height: '120px',
                      borderRadius: '12px',
                      padding: '20px',
                      textAlign: 'left',
                      marginTop: '30px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      color="#FFF"
                      fontWeight={500}
                      fontSize={12}
                    >
                      {pageData?.blocks[7]?.textTop}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="#FFF"
                      sx={{ marginTop: '-7px' }}
                      gutterBottom
                      fontWeight={600}
                      fontSize={24}
                    >
                      {pageData?.blocks[7]?.textBottom}
                    </Typography>
                    <div className="d-flex justify-content-between mt-1">
                      <Typography
                        variant="h5"
                        gutterBottom
                        color="#FFF"
                        fontWeight={400}
                        fontSize={14}
                      >
                        {pageData?.blocks[7]?.subTitle}
                      </Typography>
                      <div>
                        <MobileFriendlyOutlinedIcon htmlColor="#FFF" />
                      </div>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Box
          sx={{ marginTop: '80px', marginBottom: '70px' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Paper
            elevation={0}
            sx={{
              padding: '20px',
            }}
          >
            <img
              src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da039_GoogleStartUp.png"
              alt="google"
              style={{ height: '27px', margin: '0 auto' }}
            />
          </Paper>
          <Paper
            elevation={0}
            sx={{
              padding: '20px',
            }}
          >
            <img
              src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da045_PCINode.png"
              alt="PCI"
              style={{ height: '27px', margin: '0 auto' }}
            />
          </Paper>
          <Paper
            elevation={0}
            sx={{
              padding: '20px',
            }}
          >
            <img
              src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da035_makeNode.png"
              alt="make integromat"
              style={{ height: '29px', margin: '0 auto' }}
              className="hide-on-mobile"
            />
          </Paper>
          <Paper
            elevation={0}
            sx={{
              padding: '20px',
            }}
          >
            <img
              src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da03e_ProductHuntNode.png"
              alt="product hunt"
              style={{ height: '27px', margin: '0 auto' }}
              className="hide-on-mobile"
            />
          </Paper>
        </Box>{' '}
        <div className="mt-5 mb-5">
          <Paper
            elevation={0}
            sx={{
              backgroundColor: '#F5F5F5',
              borderRadius: '12px',
              padding: '40px',
              textAlign: 'center',
              marginTop: '50px',
              marginBottom: '150px',
            }}
          >
            {' '}
            <SupportAgentOutlinedIcon
              sx={{ marginTop: '15px' }}
              fontSize="large"
            />
            <div className="mb-5">
              <Typography
                variant="h5"
                gutterBottom
                sx={{ marginTop: '15px' }}
                fontWeight={600}
                fontSize={32}
              >
                {t('realServiceNoLimits')}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                fontWeight={400}
                fontSize={14}
              >
                {t('realServiceNoLimitsDesc')}
              </Typography>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <div>
                <ThumbUpAltOutlinedIcon />
                <Typography
                  variant="h6"
                  gutterBottom
                  fontWeight={600}
                  fontSize={18}
                >
                  {t('noOnboardFees')}
                </Typography>
              </div>
              <div>
                <ThumbUpAltOutlinedIcon />
                <Typography
                  variant="h6"
                  gutterBottom
                  fontWeight={600}
                  fontSize={18}
                >
                  {t('noMigrationFees')}
                </Typography>
              </div>
              <div>
                <ThumbUpAltOutlinedIcon />
                <Typography
                  variant="h6"
                  gutterBottom
                  fontWeight={600}
                  fontSize={18}
                >
                  {t('freeCustomerService')}
                </Typography>
              </div>
            </div>
          </Paper>
        </div>
        <div className="mt-5 mb-5 px-5">
          <div className="row align-c">
            <Typography
              variant="h5"
              gutterBottom
              sx={{ marginTop: '155px', maxWidth: '720px' }}
              color="black"
              fontWeight={600}
              fontSize={32}
            >
              {pageData?.CTAheaderLabel}
            </Typography>
          </div>
          <div className="row align-c mb-3">
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                paddingLeft: '10%',
                paddingRight: '10%',
                marginTop: '-5px',
              }}
              color="black"
              fontWeight={500}
              fontSize={16}
            >
              {t('whereWeWasDesc')}
            </Typography>{' '}
          </div>
          <Grid container spacing={3} mb={6}>
            <Grid item xs={12} md={6}>
              <div className="row mt-5">
                <div className="col-12 align-left">
                  <img
                    src={`/assets/website/platform/${pageData?.features[0]?.img}.png`}
                    alt="Node"
                    style={{ height: '130px', maxWidth: '90%' }}
                  />
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="black"
                    fontWeight={600}
                    fontSize={20}
                  >
                    {pageData?.features[0]?.title}
                  </Typography>
                  <GeneralText
                    text={pageData?.features[0]?.description}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                  />
                  <GeneralText
                    text={pageData?.features[0]?.description1}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                    classNameComponent="mt-2 mb-2"
                  />
                  <GeneralText
                    text={pageData?.features[0]?.description2}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="row mt-5">
                <div className="col-12 align-left">
                  <img
                    src={`/assets/website/platform/${pageData?.features[1]?.img}.png`}
                    alt="Node"
                    style={{ height: '130px', maxWidth: '90%' }}
                  />
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="black"
                    fontWeight={600}
                    fontSize={20}
                  >
                    {pageData?.features[1]?.title}
                  </Typography>
                  <GeneralText
                    text={pageData?.features[1]?.description}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                  />
                  <GeneralText
                    text={pageData?.features[1]?.description1}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                    classNameComponent="mt-2 mb-2"
                  />
                  <GeneralText
                    text={pageData?.features[1]?.description2}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '200px' }}>
            <Grid item xs={12} md={6}>
              <div className="row mt-5">
                <div className="col-12 align-left">
                  <img
                    src={`/assets/website/platform/${pageData?.features[2]?.img}.png`}
                    alt="Node"
                    style={{ height: '130px', maxWidth: '90%' }}
                  />
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="black"
                    fontWeight={600}
                    fontSize={20}
                  >
                    {pageData?.features[2]?.title}
                  </Typography>
                  <GeneralText
                    text={pageData?.features[2]?.description}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                  />
                  <GeneralText
                    text={pageData?.features[2]?.description1}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                    classNameComponent="mt-2 mb-2"
                  />
                  <GeneralText
                    text={pageData?.features[2]?.description2}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="row mt-5">
                <div className="col-12 align-left">
                  <img
                    src={`/assets/website/platform/${pageData?.features[3]?.img}.png`}
                    alt="Node"
                    style={{ height: '130px', maxWidth: '90%' }}
                  />

                  <Typography
                    variant="h4"
                    gutterBottom
                    color="black"
                    fontWeight={600}
                    fontSize={20}
                  >
                    {pageData?.features[3]?.title}
                  </Typography>
                  <GeneralText
                    text={pageData?.features[3]?.description}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                  />
                  <GeneralText
                    text={pageData?.features[3]?.description1}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                    classNameComponent="mt-2 mb-2"
                  />
                  <GeneralText
                    text={pageData?.features[3]?.description2}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: '#F5F5F5',
            borderRadius: '12px',
            textAlign: 'center',
            marginTop: '200px',
            marginBottom: '200px',
            paddingTop: '40px',
            paddingLeft: '40px',
            paddingRight: '40px',
          }}
        >
          <div className="row">
            <div className="col-md-6 col-12">
              <img
                src="/assets/website/img/node-mobile-business.png"
                alt="real service no limits"
                style={{ height: 'auto', width: '100%' }}
              />
            </div>
            <div className="col-md-6 col-12 align-c">
              <img
                src={`/assets/website/platform/mobile-continue.gif`}
                alt="Node"
                style={{ height: '115px', width: '200px' }}
              />
              <Typography
                variant="h5"
                gutterBottom
                sx={{ marginTop: '15px' }}
                fontWeight={600}
                fontSize={32}
              >
                {t('openYourHorizons')}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                fontWeight={400}
                fontSize={14}
              >
                {t('openYourHorizonsDesc')}
              </Typography>
            </div>
          </div>
        </Paper>
        <div>
          <div className="mt-5 px-5">
            <Typography
              variant="h5"
              gutterBottom
              sx={{ marginTop: '155px' }}
              fontWeight={600}
              fontSize={32}
            >
              {t('thisIsNode')}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                paddingLeft: '10%',
                paddingRight: '10%',
                marginTop: '-5px',
              }}
              fontWeight={500}
              fontSize={24}
            >
              {t('thisIsNodeDesc')}
            </Typography>
          </div>
          <img
            style={{ marginTop: '-10px' }}
            src={`/assets/website/${contentName}.jpg`}
            width="700px"
            alt={contentName}
          />
        </div>
        <div className="mt-5 mb-5">
          <div className="mt-5 mb-5 px-5">
            <Typography
              variant="h5"
              gutterBottom
              sx={{ marginTop: '155px' }}
              fontWeight={600}
              fontSize={32}
            >
              {t('everythingYouNeed')}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                paddingLeft: '10%',
                paddingRight: '10%',
                marginTop: '-5px',
              }}
              fontWeight={500}
              fontSize={24}
            >
              {t('everythingYouNeedDesc')}
            </Typography>
          </div>
          <CompareGrid contentName={contentName} />
        </div>
        <BannerLeft
          title={t('handsFreeMobile')}
          subText={t('handsFreeMobileText')}
          mainImg="/assets/website/img/sport-node.png"
          alignement="right"
          tile1={'Search-bar-ID'}
          faqType="mobile"
          mainImgSub="/assets/website/img/manufacturer-node.png"
        />
        <BothActions
          title1={t('discussAboutBusiness')}
          title2={t('discoverModules')}
          subText1={t('discussAboutBusinessText')}
          subText2={t('discoverModulesText')}
          titleComponent={t('alwaysHereToHelp')}
          icon1={
            <MarkChatReadOutlinedIcon htmlColor="#FFFFFF" fontSize="large" />
          }
        />
      </Container>
    </WebsiteLayout>
  );
};

export default Industries;
