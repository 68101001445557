import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactFlow, {
  MiniMap,
  applyEdgeChanges,
  applyNodeChanges,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  Handle,
  addEdge,
} from 'react-flow-renderer';
import { db } from '../../firebase';
import { doc, collection, query, where, getDocs } from 'firebase/firestore';
import { useTheme } from '@mui/material/styles';
import 'reactflow/dist/style.css';

//components
import MainLayoutV2 from '../../layouts/MainLayoutV2';
import Block from '../../stories/layout-components/Block';
import {
  AddCircleOutline,
  ChatBubbleOutline,
  EmailOutlined,
  NotificationAddOutlined,
  RemoveCircleOutline,
  Timer10Rounded,
  UpdateOutlined,
} from '@mui/icons-material';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import {
  Dialog,
  DialogActions,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import TextField from '../../stories/general-components/TextField';
import Blocks from '../../stories/layout-components/Block';
import Select from '../../stories/general-components/Select';
import Checkbox from '../../stories/general-components/Checkbox';
import Button from '../../stories/general-components/Button';

const Automations = () => {
  const { t, i18n } = useTranslation();
  const currentLangCode = i18n.language;
  const [dialogOpen, setDialogOpen] = useState(false);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const businessPreference = useSelector((state) => state.core.businessData);

  const EmailNode = ({ data }) => {
    return (
      <div
        style={{
          backgroundColor: businessPreference?.mainColor + '90' || '#000',
          padding: '10px',
          borderRadius: '14px',
          width: '40px',
          textAlign: 'center',
          height: '40px',
        }}
      >
        <Handle type="target" position="top" />
        <EmailOutlined color="white" />
        <Handle type="source" position="bottom" />
      </div>
    );
  };

  const DelayNode = () => {
    return (
      <div
        style={{
          backgroundColor: '#000',
          padding: '10px',
          borderRadius: '14px',
          width: '40px',
          textAlign: 'center',
          height: '40px',
        }}
      >
        <Handle type="target" position="top" />
        <Timer10Rounded color="white" />
        <Handle type="source" position="bottom" />
      </div>
    );
  };

  const DeleteNode = () => {
    return (
      <div
        style={{
          backgroundColor: businessPreference?.secColor + '80' || '#000',
          padding: '10px',
          borderRadius: '14px',
          width: '40px',
          textAlign: 'center',
          height: '40px',
        }}
      >
        <Handle type="target" position="top" />
        <RemoveCircleOutline color="white" />
        <Handle type="source" position="bottom" />
      </div>
    );
  };

  const UpdateNode = () => {
    return (
      <div
        style={{
          backgroundColor: businessPreference?.secColor + '95' || '#000',
          padding: '10px',
          borderRadius: '14px',
          width: '40px',
          textAlign: 'center',
          height: '40px',
        }}
      >
        <Handle type="target" position="top" />
        <UpdateOutlined color="white" />
        <Handle type="source" position="bottom" />
      </div>
    );
  };

  const CreateNode = () => {
    return (
      <div
        style={{
          backgroundColor: businessPreference?.secColor || '#000',
          padding: '10px',
          borderRadius: '14px',
          width: '40px',
          textAlign: 'center',
          height: '40px',
        }}
      >
        <Handle type="target" position="top" />
        <AddCircleOutline color="white" />
        <Handle type="source" position="bottom" />
      </div>
    );
  };

  const NotificationNode = () => {
    return (
      <div
        style={{
          backgroundColor: businessPreference?.mainColor + '95' || '#000',
          padding: '10px',
          borderRadius: '14px',
          width: '40px',
          textAlign: 'center',
          height: '40px',
        }}
      >
        <Handle type="target" position="top" />
        <NotificationAddOutlined color="white" />
        <Handle type="source" position="bottom" />
      </div>
    );
  };

  const MessageNode = () => {
    return (
      <div
        style={{
          backgroundColor: businessPreference?.mainColor || '#000',
          padding: '10px',
          borderRadius: '14px',
          width: '40px',
          textAlign: 'center',
          height: '40px',
        }}
      >
        <Handle type="target" position="top" />
        <ChatBubbleOutline color="white" />
        <Handle type="source" position="bottom" />
      </div>
    );
  };

  const Quickbooks = ({ data }) => {
    return (
      <div
        style={{
          backgroundColor: '#FFF',
          padding: '10px',
          borderRadius: '14px',
          width: '40px',
          textAlign: 'center',
          height: '40px',
        }}
      >
        <Handle type="target" position="top" />
        <img
          src="/assets/v3/connectors/quickbooks.png"
          alt="quickbooks"
          style={{ width: '20px' }}
        />
        <Handle type="source" position="bottom" />
      </div>
    );
  };

  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const structures = businessStructure?.structures;
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [workflowId, setWorkflowId] = useState('');
  const [name, setName] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [condition, setCondition] = useState('');
  const [selectedNode, setSelectedNode] = useState({});
  const [structureId, setStructureId] = useState('');
  const [trigger, setTrigger] = useState('');
  const [workflows, setWorkflows] = useState([]);
  const [dialogConditionOpen, setDialogConditionOpen] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [tags, setTags] = useState([]);

  const formattedStructures = useMemo(() => {
    return structures?.map((structure) => ({
      id: structure?.id,
      label: structure[`name`],
      value: structure?.id,
    }));
  }, [structures, currentLangCode]);

  const formattedFields = useMemo(() => {
    //find fields from structureId selected
    const structure = structures?.find((s) => s.id === structureId);
    const fields = structure?.fields;
    return fields?.map((field) => ({
      id: field?.value,
      label: field[`name`],
      value: field?.value,
    }));
  }, [structureId, structures, currentLangCode]);

  const nodeTypes = useMemo(
    () => ({
      emailNode: EmailNode,
      delayNode: DelayNode,
      updateNode: UpdateNode,
      deleteNode: DeleteNode,
      createNode: CreateNode,
      notificationNode: NotificationNode,
      messageNode: MessageNode,
      quickbooks: Quickbooks,
    }),
    []
  );

  const handleEditNode = (event, node) => {
    setSelectedNode(node);
    setSelectedType(node?.type);
    setDialogOpen(true);
  };

  const saveWorkflow = async () => {
    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `automations-setWorkflow`,
        body: {
          flowDetails: {
            name: name,
            tags: tags || [],
            isActive: isActive,
            steps: nodes,
            condition: condition,
            structureId: structureId,
            collection: '',
            trigger: trigger,
            edges: edges,
          },
          workflowId: workflowId || null,
        },
      });
    } catch (error) {
      console.error('Error set flow master:', error);
    }
  };
  const stepDistance = 200;

  const handleUpdateCondition = (index, field, operator, value) => {
    const newCondition = condition?.map((c, i) => {
      if (i === index) {
        return {
          key: field,
          operator: operator,
          value: value,
        };
      }
      return c;
    });
    setCondition(newCondition);
  };

  const handleSelectFlow = (flow) => {
    setWorkflowId(flow?.id);
    setName(flow?.name);
    setIsActive(flow?.isActive);
    setCondition(flow?.condition);
    setStructureId(flow?.structureId);

    setTrigger(flow?.trigger);
    const newNodes = flow?.steps?.map((step, index) => ({
      id: `node-${index}`,
      type: step.type,
      position: { x: 0, y: index * stepDistance },
      data: { label: step.label, value: step.value },
    }));

    setNodes(newNodes);
    setEdges(flow?.edges);
  };

  const getWorkflows = async () => {
    try {
      const collectionRef = collection(db, 'workflows');
      const businessRef = doc(db, 'businessesOnNode', businessPreference?.id);
      const q = query(collectionRef, where('ownerId', '==', businessRef));

      const querySnapshot = await getDocs(q);
      const workflows = [];
      querySnapshot.forEach((doc) => {
        workflows.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setWorkflows(workflows);
    } catch (error) {
      console.error('Error get flow master:', error);
    }
  };

  useEffect(() => {
    getWorkflows();
  }, []);

  const removeElements = (elementsToRemove, elements) => {
    return elements.filter(
      (el) => !elementsToRemove.find((er) => er.id === el.id)
    );
  };

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const onElementsRemove = (elementsToRemove) => {
    setNodes((nodes) => removeElements(elementsToRemove, nodes));
  };

  const nodeColor = (node) => {
    switch (node.type) {
      default:
        return businessPreference?.mainColor || '#000';
    }
  };

  const handleAddNode = () => {
    setDialogOpen(true);
  };

  const handleAddNodeMew = () => {
    const newNode = {
      id: `node-${nodes?.length + 1}`,
      type: selectedType,
      data: {
        parameters: {
          type: 'internal',
          subType: selectedType,
        },
        action: '',
        dataDocument: {},
      },
      position: {
        x: 100,
        y: nodes?.length * 10,
      },
    };
    setNodes([...(nodes || []), newNode]);
    setEdges([
      ...(edges || []),
      {
        id: `edge-${edges?.length + 1}`,
        source: nodes[nodes?.length - 1]?.id,
        target: newNode?.id,
      },
    ]);
    setDialogOpen(false);
    setSelectedNode({});
    setSelectedType('');
  };

  const updateNode = () => {
    // Find the index of the node to update
    const nodeIndex = nodes.findIndex((n) => n.id === selectedNode.id);

    const updatedNode = {
      ...selectedNode,
      type: selectedType,
    };

    const newNodes = [
      ...nodes.slice(0, nodeIndex),
      updatedNode,
      ...nodes.slice(nodeIndex + 1),
    ];

    setNodes(newNodes);
    setDialogOpen(false);
    setSelectedNode({});
    setSelectedType('');
  };

  const handleAddFlow = () => {
    setName(t('new'));
    setIsActive(true);
    setNodes([]);
    setEdges([]);
    setCondition('');
    setStructureId('');
    setTrigger('');
    setTimeout(() => {
      saveWorkflow();
      getWorkflows();
    }, 1000);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogConditionOpen(false);
    setSelectedNode({});
    setSelectedType('');
  };

  const handleCondition = () => {
    setDialogConditionOpen(true);
  };

  return (
    <MainLayoutV2
      pageTitle={workflowId ? name : t('automations')}
      elementId="node"
      add={workflowId ? handleAddNode : handleAddFlow}
      save={workflowId && saveWorkflow}
    >
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <div className="d-flex p-4 align-c">
          <div
            className="col-1 align-c hover"
            style={{
              backgroundColor:
                selectedType === 'emailNode'
                  ? businessPreference?.mainColor + '15'
                  : '',
              padding: '5px',
              borderRadius: '10px',
            }}
            onClick={() => setSelectedType('emailNode')}
          >
            <EmailOutlined />
            <Typography fontSize="10px">{t('sendEmail')}</Typography>
          </div>
          <div
            className="col-1 align-c hover"
            style={{
              backgroundColor:
                selectedType === 'messageNode'
                  ? businessPreference?.mainColor + '15'
                  : '',
              padding: '5px',
              borderRadius: '10px',
            }}
            onClick={() => setSelectedType('messageNode')}
          >
            <ChatBubbleOutline />
            <Typography fontSize="10px">{t('messageChannel')}</Typography>
          </div>
          <div
            className="col-1 align-c hover"
            style={{
              backgroundColor:
                selectedType === 'updateNode'
                  ? businessPreference?.mainColor + '15'
                  : '',
              padding: '5px',
              borderRadius: '10px',
            }}
            onClick={() => setSelectedType('updateNode')}
          >
            <UpdateOutlined />
            <Typography fontSize="10px">{t('updateDocument')}</Typography>
          </div>
          <div
            className="col-1 align-c hover"
            style={{
              backgroundColor:
                selectedType === 'createNode'
                  ? businessPreference?.mainColor + '15'
                  : '',
              padding: '5px',
              borderRadius: '10px',
            }}
            onClick={() => setSelectedType('createNode')}
          >
            <AddCircleOutline />
            <Typography fontSize="10px">{t('createDocument')}</Typography>
          </div>
          <div
            className="col-1 align-c hover"
            style={{
              backgroundColor:
                selectedType === 'notificationNode'
                  ? businessPreference?.mainColor + '15'
                  : '',
              padding: '5px',
              borderRadius: '10px',
            }}
            onClick={() => setSelectedType('notificationNode')}
          >
            <NotificationAddOutlined />
            <Typography fontSize="10px">{t('sendNotification')}</Typography>
          </div>
          <div
            className="col-1 align-c hover"
            style={{
              backgroundColor:
                selectedType === 'delayNode'
                  ? businessPreference?.mainColor + '15'
                  : '',
              padding: '5px',
              borderRadius: '10px',
            }}
            onClick={() => setSelectedType('delayNode')}
          >
            <Timer10Rounded />
            <Typography fontSize="10px">{t('addDelay')}</Typography>
          </div>
          <div
            className="col-1 align-c hover"
            style={{
              backgroundColor:
                selectedType === 'deleteNode'
                  ? businessPreference?.mainColor + '15'
                  : '',
              padding: '5px',
              borderRadius: '10px',
            }}
            onClick={() => setSelectedType('deleteNode')}
          >
            <RemoveCircleOutline />
            <Typography fontSize="10px">{t('deleteDocument')}</Typography>
          </div>
        </div>
        <DialogActions>
          <Button
            variant="text"
            label={t('cancel')}
            onClick={handleCloseDialog}
          />
          <Button label={t('save')} onClick={handleAddNodeMew} />
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogConditionOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <div className=" p-4">
          {condition &&
            condition?.map((c, index) => {
              return (
                <div className="row d-flex align-c hover" key={index}>
                  <div className="col-5">
                    <Select
                      fullWidth
                      label={t('field')}
                      value={c?.key}
                      selections={formattedFields}
                      onChange={(e, id) =>
                        handleUpdateCondition(index, id, c?.operator, c?.value)
                      }
                    />{' '}
                  </div>
                  <div className="col-2">
                    <Select
                      fullWidth
                      label={''}
                      value={c?.operator}
                      selections={[
                        {
                          id: '==',
                          label: '==',
                          value: '==',
                        },
                        {
                          id: '!=',
                          label: '!=',
                          value: '!=',
                        },
                        {
                          id: '<=',
                          label: '<=',
                          value: '<=',
                        },
                        {
                          id: '>=',
                          label: '>=',
                          value: '>=',
                        },
                      ]}
                      onChange={(e, id) =>
                        handleUpdateCondition(index, c.key, id, c?.value)
                      }
                    />{' '}
                  </div>
                  <div className="col-5">
                    <TextField
                      fullWidth
                      label={t('value')}
                      value={c.value}
                      onChange={(e) =>
                        handleUpdateCondition(
                          index,
                          c.key,
                          c.operator,
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <DialogActions>
          <Button
            variant="text"
            label={t('addNew')}
            onClick={() =>
              setCondition([
                ...condition,
                { field: '', operator: '==', value: '' },
              ])
            }
          />
          <Button label={t('save')} onClick={handleCloseDialog} />
        </DialogActions>
      </Dialog>
      <Block height={1} heightPercentage={82}>
        {workflowId === '' ? (
          <div>
            <List
              sx={{
                backgroundColor: isDarkMode ? 'rgb(51,51,51)' : '',
                padding: '10px',
                borderRadius: '14px',
              }}
            >
              {workflows?.map((workflow) => {
                return (
                  <ListItem
                    button
                    divider
                    key={workflow?.id}
                    onClick={() => handleSelectFlow(workflow)}
                    sx={{
                      backgroundColor: isDarkMode ? 'rgb(51,51,51)' : '',
                      padding: '10px',
                      borderRadius: '14px',
                      marginBottom: '10px',
                      cursor: 'pointer',
                    }}
                  >
                    {workflow?.name}
                  </ListItem>
                );
              })}
            </List>
          </div>
        ) : (
          <div
            style={{
              height: '100%',
              width: '100%',
              position: 'relative',
            }}
          >
            {workflowId && (
              <div
                style={{
                  position: 'absolute',
                  right: 0,

                  width: '300px',
                  zIndex: 1000,
                }}
              >
                <Blocks noPadding>
                  <div className="p-4">
                    <TextField
                      fullWidth
                      label={t('name')}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Select
                      fullWidth
                      label={t('trigger')}
                      value={trigger}
                      selections={[
                        {
                          id: 'onCreate',
                          label: t('onCreate'),
                          value: 'onCreate',
                        },
                        {
                          id: 'onUpdate',
                          label: t('onUpdate'),
                          value: 'onUpdate',
                        },
                        {
                          id: 'onDelete',
                          label: t('onDelete'),
                          value: 'onDelete',
                        },
                      ]}
                      onChange={(e, value) => setTrigger(value)}
                    />
                    <Select
                      fullWidth
                      label={t('structure')}
                      value={structureId}
                      selections={formattedStructures}
                      onChange={(e, value) => setStructureId(value)}
                    />

                    <Checkbox
                      label={t('isActive')}
                      value={isActive}
                      onChange={(e) => setIsActive(e.target.checked)}
                      onBlur={(e) => setIsActive(e.target.checked)}
                    />
                    <div className="mt-2">
                      <Button
                        fullWidth
                        variant="text"
                        label={condition?.length + ' ' + 'conditions'}
                        onClick={handleCondition}
                      />
                    </div>
                  </div>
                </Blocks>
              </div>
            )}
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onElementsRemove={onElementsRemove}
              onConnect={onConnect}
              onNodeClick={handleEditNode}
              nodeColor={nodeColor}
              fitView
            >
              <Controls />
              <MiniMap
                nodeColor={nodeColor}
                nodeStrokeWidth={3}
                zoomable
                pannable
              />
              <Background variant="dots" gap={12} size={0.3} />
            </ReactFlow>
          </div>
        )}
      </Block>
    </MainLayoutV2>
  );
};

export default Automations;
