import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import WebsiteLayout from '../../layouts/WebsiteLayout';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {
  AccountBalanceOutlined,
  ArrowForward,
  AssessmentOutlined,
  ContactMailOutlined,
  ExpandMore,
  HomeRepairServiceOutlined,
  Inventory2Outlined,
  LocalGroceryStoreOutlined,
  Receipt,
  ReceiptOutlined,
  Send,
  SummarizeOutlined,
} from '@mui/icons-material';

const Platform = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const colors = ['error', 'primary', '#BDDC11'];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const lang = localStorage.getItem('i18nextLng');

  const handleSendEmail = () => {
    console.log('Send email');
  };

  const Star = ({ filled }) => (
    <span style={{ color: filled ? 'gold' : 'gray' }}>★</span>
  );

  const StarRating = ({ rating }) => {
    return (
      <div>
        {[...Array(5)].map((_, i) => (
          <Star key={i} filled={i < rating} />
        ))}
      </div>
    );
  };

  return (
    <WebsiteLayout full={true}>
      <Helmet>
        <title>{t('nodeTitleSEO')}</title>
        <meta name="description" content={t('nodeDescriptionSEO')} />
        <meta
          name="keywords"
          content={[
            t('businessManagement'),
            'Node',
            'usenode',
            'SaaS',
            t('software'),
            'ERP',
            'CRM',
          ]}
        />

        <link
          rel="icon"
          type="image/png"
          href={`https://storage.googleapis.com/avatars_node/nodetechnologies.png`}
          sizes="16x16"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charset="UTF-8" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:title" content={t('nodeTitleSEO')} />
        <meta property="og:description" content={t('nodeDescriptionSEO')} />
        <meta name="twitter:title" content={t('nodeTitleSEO')} />
        <link rel="canonical" href={`https://usenode.com/`} />
        <meta property="og:url" content={`https://usenode.com/`} />
      </Helmet>
      <div className="mt-5">
        <div
          style={{
            maxWidth: isMobile ? '95%' : '80%',
            marginLeft: isMobile ? '2.5%' : '10%',
            marginTop: '90px',
          }}
          className="row"
        >
          <div
            style={{
              width: '200px',
              marginTop: '400px',
              height: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              zIndex: 0,
            }}
            className="hide-on-mobile"
          >
            <img
              src="/assets/website/2.0/mesh-78.png"
              alt="arrow"
              className="hide-on-mobile"
              style={{
                opacity: 0.07,
                rotate: '20deg',
                width: '900px',
                marginLeft: '-120px',
                borderRadius: '70px',
                left: 0,
              }}
            />
          </div>
          <div
            style={{
              width: '200px',
              height: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              marginLeft: '320px',
              zIndex: 2,
              right: 0,
            }}
            className="hide-on-mobile"
          >
            <img
              src="/assets/website/2.0/mesh-79.png"
              alt="arrow"
              className="hide-on-mobile"
              style={{
                opacity: 0.22,
                rotate: '20deg',
                width: '900px',
                marginTop: '-60px',
                marginLeft: '220px',
                borderRadius: '70px',
                right: 0,
                zIndex: 0,
              }}
            />
          </div>
          <div className="col-md-4 col-12">
            <img
              src="/assets/website/2.0/NodeVisual.png"
              alt="arrow"
              style={{
                width: '50vh',
                borderRadius: '20px',
                maxWidth: '500px',
                marginTop: '50px',
                marginLeft: isMobile ? '0%' : '16%',
                zIndex: 3,
              }}
            />
          </div>
          <div
            style={{
              maxWidth: isMobile ? '90%' : '50%',
              marginLeft: isMobile ? '5%' : '10%',
            }}
            className="col-md-8 col-12 align-left"
          >
            <div className="mb-3">
              {' '}
              <Typography
                fontSize={isMobile ? 30 : 44}
                fontWeight={600}
                lineHeight={1.1}
                mt={5}
              >
                {t('nodePilot')}
              </Typography>
              <Typography fontSize={isMobile ? 16 : 18} mt={4} fontWeight={500}>
                {t('nodePilotText')}
              </Typography>
            </div>

            <StarRating rating={5} />
          </div>
        </div>
        <div
          style={{
            maxWidth: '80%',
            marginLeft: '10%',
            marginTop: '180px',
            marginBottom: '80px',
          }}
          className="row"
        >
          <div className="col-md-4 col-12 px-3 mt-3">
            <div
              style={{
                backgroundColor: '#F2f2f2',
                height: '200px',
                borderRadius: '20px',
              }}
              className="row align-left"
            >
              <div className="col-5">
                <img
                  src="/assets/website/2.0/dragDrop.png"
                  alt="arrow"
                  style={{
                    width: '100px',
                    borderRadius: '0px',
                    marginLeft: '14%',
                    marginTop: '50px',
                    zIndex: 3,
                  }}
                />
              </div>
              <div className="col-7">
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  lineHeight={1.1}
                  mt={5}
                >
                  {t('dragNDrop')}
                </Typography>
                <Typography
                  fontSize={13}
                  fontWeight={500}
                  lineHeight={1.1}
                  mt={3}
                >
                  {t('dragNDropText')}
                </Typography>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 px-3 mt-3">
            <div
              style={{
                backgroundColor: '#F2f2f2',
                height: '200px',
                borderRadius: '20px',
              }}
              className="row align-left"
            >
              <div className="col-5">
                <img
                  src="/assets/website/2.0/access.png"
                  alt="arrow"
                  style={{
                    width: '100px',
                    borderRadius: '0px',
                    marginLeft: '14%',
                    marginTop: '50px',
                    zIndex: 3,
                  }}
                />
              </div>
              <div className="col-7">
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  lineHeight={1.1}
                  mt={5}
                >
                  {t('unlimitedAccess')}
                </Typography>
                <Typography
                  fontSize={13}
                  fontWeight={500}
                  lineHeight={1.1}
                  mt={3}
                >
                  {t('unlimitedAccessText')}
                </Typography>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 px-3 mt-3">
            <div
              style={{
                backgroundColor: '#F2f2f2',
                height: '200px',
                borderRadius: '20px',
              }}
              className="row align-left"
            >
              <div className="col-5">
                <img
                  src="/assets/website/2.0/everywhere.png"
                  alt="arrow"
                  style={{
                    width: '100px',
                    borderRadius: '0px',
                    marginLeft: '14%',
                    marginTop: '50px',
                    zIndex: 3,
                  }}
                />
              </div>
              <div className="col-7">
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  lineHeight={1.1}
                  mt={5}
                >
                  {t('everyWhere')}
                </Typography>
                <Typography
                  fontSize={13}
                  fontWeight={500}
                  lineHeight={1.1}
                  mt={3}
                >
                  {t('everyWhereText')}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            maxWidth: '80%',
            marginLeft: '10%',
            marginRight: '10%',
            padding: '4%',
          }}
          className="row"
        >
          <div className="col-md-6 col-12 align-left px-4">
            <Typography fontSize={30} fontWeight={600} lineHeight={1.1} mt={5}>
              {t('unifiedStructures')}
            </Typography>
            <Typography fontSize={18} fontWeight={500} lineHeight={1.1} mt={3}>
              {t('unifiedStructuresText')}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              disableElevation
              size="large"
              sx={{ mt: 3 }}
              onClick={() => navigate('/contact')}
            >
              {t('contactUs')}
            </Button>
            <Button
              variant="text"
              color="primary"
              disableElevation
              size="large"
              sx={{ mt: 3, mx: 3 }}
              onClick={() => navigate('/pricing')}
            >
              {t('pricing')}{' '}
              <ArrowForward sx={{ marginLeft: isMobile ? '' : '4px' }} />
            </Button>
          </div>
          <div className="col-md-6 col-12 mt-4">
            <div
              style={{
                width: '90%',
                height: isMobile ? '280px' : '380px',
                borderRadius: '20px',
                background:
                  'linear-gradient(to bottom, #200EF030 0%, #200EF002 95%)',
              }}
            >
              {' '}
              <img
                src="/assets/website/2.0/structures.png"
                alt="structures"
                style={{
                  width: isMobile ? '35vh' : '50vh',
                  borderRadius: '20px',
                  marginLeft: isMobile ? '0px' : '60px',
                  maxWidth: '580px',
                  marginTop: isMobile ? '60px' : '80px',
                  zIndex: 3,
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: '30px',
            maxWidth: '80%',
            marginLeft: '10%',
            marginRight: '10%',
            padding: '5%',
          }}
          className="row mb-5"
        >
          <div className="col-md-6 col-12">
            <div
              style={{
                width: '90%',
                height: isMobile ? '280px' : '380px',
                borderRadius: '20px',
                background:
                  'linear-gradient(to bottom, #FFE4E4 0%, #FFE4E402 95%)',
              }}
            >
              <img
                src="/assets/website/2.0/mobile.png"
                alt="mobile-assets"
                style={{
                  width: isMobile ? '40vh' : '60vh',
                  borderRadius: '20px',
                  maxWidth: '480px',
                  marginTop: isMobile ? '30px' : '80px',
                  zIndex: 3,
                }}
              />
            </div>
          </div>
          <div className="col-md-6 col-12 align-left px-4">
            <Typography fontSize={30} fontWeight={600} lineHeight={1.1} mt={5}>
              {t('businessManagement')}
            </Typography>
            <Typography fontSize={18} fontWeight={500} lineHeight={1.1} mt={3}>
              {t('businessManagementText')}
            </Typography>
            <div style={{ marginLeft: '-30px' }} className="row mt-4 mb-2">
              <div className="col-md-3 col-6 align-c">
                <Inventory2Outlined />
                <Typography variant="body1">{t('inventory')}</Typography>
              </div>
              <div className="col-md-3 col-6 align-c">
                <ContactMailOutlined />
                <Typography variant="body1">{t('contacts')}</Typography>
              </div>
              <div className="col-md-3 col-6 align-c">
                <AccountBalanceOutlined />
                <Typography variant="body1">{t('finances')}</Typography>
              </div>
              <div className="col-md-3 col-6 align-c">
                <ReceiptOutlined />
                <Typography variant="body1">{t('payments')}</Typography>
              </div>
            </div>
            <div style={{ marginLeft: '-30px' }} className="row mb-3 mt-3">
              <div className="col-md-3 col-6 align-c">
                <HomeRepairServiceOutlined />
                <Typography variant="body1">{t('services')}</Typography>
              </div>
              <div className="col-md-3 col-6 align-c">
                <LocalGroceryStoreOutlined />
                <Typography variant="body1">{t('sales')}</Typography>
              </div>
              <div className="col-md-3 col-6 align-c">
                <SummarizeOutlined />
                <Typography variant="body1">{t('reports')}</Typography>
              </div>
              <div className="col-md-3 col-6 align-c">
                <AssessmentOutlined />
                <Typography variant="body1">{t('dashboards')}</Typography>
              </div>
            </div>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              size="large"
              sx={{ mt: 3, mx: isMobile ? 1 : 0 }}
              onClick={() => navigate('/approch')}
            >
              {t('approch')}
            </Button>
            <Button
              variant="text"
              color="primary"
              disableElevation
              size="large"
              sx={{ mt: 3, mx: isMobile ? 0 : 3 }}
              onClick={() => navigate('/meet-node')}
            >
              {t('planDemo')}{' '}
              <ArrowForward sx={{ marginLeft: isMobile ? '' : '4px' }} />
            </Button>
          </div>
        </div>
        <Box
          className="hide-on-mobile"
          sx={{
            minHeight: '120px',
            backgroundSize: 'fit',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            backgroundColor: '#F5F5F5',
            padding: '25px 150px 25px 150px',
          }}
        >
          <div className="row py-3 middle-content ">
            <div className="col-2 align-left">
              <Typography variant="h5" gutterBottom fontWeight={600}>
                {t('connect')}
              </Typography>
              <Typography variant="p" gutterBottom fontWeight={500}>
                {t('prefSoftwares')}
              </Typography>
            </div>
            <div className="col-1">
              {' '}
              <img
                src="/assets/v3/connectors/activecampaigninactive.png"
                alt="arrow"
                style={{
                  width: '50px',
                  zIndex: 5,
                }}
              />
            </div>
            <div className="col-1">
              {' '}
              <img
                src="/assets/v3/connectors/airtableinactive.png"
                alt="arrow"
                style={{
                  width: '50px',
                  zIndex: 5,
                }}
              />
            </div>
            <div className="col-1">
              {' '}
              <img
                src="/assets/v3/connectors/mondayinactive.png"
                alt="arrow"
                style={{
                  width: '50px',
                  zIndex: 5,
                }}
              />
            </div>
            <div className="col-1">
              {' '}
              <img
                src="/assets/v3/connectors/quickbooksinactive.png"
                alt="arrow"
                style={{
                  width: '50px',
                  zIndex: 5,
                }}
              />
            </div>
            <div className="col-1">
              {' '}
              <img
                src="/assets/v3/connectors/ringcentralinactive.png"
                alt="arrow"
                style={{
                  width: '50px',
                  zIndex: 5,
                }}
              />
            </div>
            <div className="col-1">
              {' '}
              <img
                src="/assets/v3/connectors/notioninactive.png"
                alt="arrow"
                style={{
                  width: '50px',
                  zIndex: 5,
                }}
              />
            </div>
            <div className="col-1">
              {' '}
              <img
                src="/assets/v3/connectors/webflowinactive.png"
                alt="arrow"
                style={{
                  width: '50px',
                  zIndex: 5,
                }}
              />
            </div>
            <div className="col-1">
              {' '}
              <img
                src="/assets/v3/connectors/asanainactive.png"
                alt="arrow"
                style={{
                  width: '50px',
                  zIndex: 5,
                }}
              />
            </div>
            <div className="col-1">
              {' '}
              <img
                src="/assets/v3/connectors/poyntinactive.png"
                alt="arrow"
                style={{
                  width: '50px',
                  zIndex: 5,
                }}
              />
            </div>
          </div>
        </Box>
        <div
          style={{
            marginTop: '100px',
            maxWidth: isMobile ? '90%' : '80%',
            marginLeft: isMobile ? '5%' : '10%',
            marginRight: isMobile ? '5%' : '10%',
            padding: '5%',
          }}
          className="row"
        >
          <div
            className={
              isMobile
                ? 'col-md-7 col-12 align-left'
                : 'col-md-7 col-12 align-left px-4'
            }
          >
            <Typography fontSize={30} fontWeight={600} lineHeight={1.1} mt={5}>
              {t('customizeYourNode')}
            </Typography>
            <Typography fontSize={18} fontWeight={500} lineHeight={1.1} mt={3}>
              {t('customizeYourNodeText')}
            </Typography>
            <div className="mt-4">
              <img
                src="/assets/website/2.0/drag.png"
                alt="arrow"
                style={{
                  width: '600px',
                  zIndex: 5,
                }}
              />
            </div>
          </div>
          <div
            style={{ marginTop: isMobile ? '' : '100px' }}
            className="col-md-5 col-12 align-left"
          >
            <Accordion
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                marginTop: '20px',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography fontSize={14} fontWeight={600}>
                  {t('branding')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    fontSize={14}
                    gutterBottom
                  >
                    {t('brandingTitle')}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('brandingBody')}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                marginTop: '20px',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography fontSize={14} fontWeight={600}>
                  {t('fieldsData')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    fontSize={14}
                    gutterBottom
                  >
                    {t('fieldsDataTitle')}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('fieldsDataBody')}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                marginTop: '20px',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography fontSize={14} fontWeight={600}>
                  {t('accessPermissions')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    fontSize={14}
                    gutterBottom
                  >
                    {t('accessPermissionsTitle')}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('accessPermissionsBody')}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                marginTop: '20px',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography fontSize={14} fontWeight={600}>
                  {t('layoutDesign')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    fontSize={14}
                    gutterBottom
                  >
                    {t('layoutDesignTitle')}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('layoutDesignBody')}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                marginTop: '20px',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography fontSize={14} fontWeight={600}>
                  {t('integrations')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    fontSize={14}
                    gutterBottom
                  >
                    {t('integrationsTitle')}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('integrationsBody')}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <Box
          sx={{
            marginTop: '20px',
            minHeight: '100px',
            padding: '12px',
          }}
        >
          <div className="row align-c mb-5">
            <div>
              <Typography fontSize={32} fontWeight={600}>
                {t('stayInTouch')}
              </Typography>
            </div>
            <div>
              <Typography fontSize={16} fontWeight={500} gutterBottom>
                {t('stayInTouchText')}
              </Typography>
            </div>
            <div>
              <TextField
                id="outlined-basic"
                label={t('email')}
                variant="outlined"
                size="large"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleSendEmail}
                        onMouseDown={handleSendEmail}
                        edge="end"
                      >
                        <Send />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  marginTop: '20px',
                  maxWidth: '500px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '14px',
                  },
                }}
              />
            </div>
          </div>
        </Box>
      </div>
    </WebsiteLayout>
  );
};

export default Platform;
