import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import WebsiteLayout from '../../layouts/WebsiteLayout';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import HomeBusiness from './components/HomeBusiness';
import Exercices from '../mainPages/Exercices';

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const colors = ['error', 'primary', '#BDDC11'];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const lang = localStorage.getItem('i18nextLng');

  return (
    <WebsiteLayout full={true}>
      <Helmet>
        <title>{t('nodeTitleSEO')}</title>
        <meta name="description" content={t('nodeDescriptionSEO')} />
        <meta
          name="keywords"
          content={[
            t('businessManagement'),
            'Node',
            'usenode',
            'SaaS',
            t('software'),
            'ERP',
            'CRM',
          ]}
        />

        <link
          rel="icon"
          type="image/png"
          href={`https://storage.googleapis.com/avatars_node/nodetechnologies.png`}
          sizes="16x16"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charset="UTF-8" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:title" content={t('nodeTitleSEO')} />
        <meta property="og:description" content={t('nodeDescriptionSEO')} />
        <meta name="twitter:title" content={t('nodeTitleSEO')} />
        <link rel="canonical" href={`https://usenode.com/`} />
        <meta property="og:url" content={`https://usenode.com/`} />
      </Helmet>
      <div>
        <HomeBusiness isMobile={isMobile} />
      </div>
    </WebsiteLayout>
  );
};

export default HomePage;
